<script>
export default {
  name: "EodAnalytics",
  props: [
    'analysis',
    'date_range',
  ],
  data() {
    return {
      order_count: 0,
      orders: [
        {
          unit: "Mon",
          orders: 0,
          products: 0,
          items: 0,
          percent: 0,
          amount: 0,
        },
      ],
      courier: [
        {
          name: "",
          count: 0,
          percent: 0,
          total_fees: 0,
        },
      ],    
      payment_mode: [
        {
          mode: 0,
          count: 0,
          revenue: 0,
        },
      ],    
    };
  },
  methods: {
    updateReport() {
      this.payment_mode = this.analysis.payment_mode
      this.courier = this.analysis.courier
    }
  },
  computed: {
  },
  mounted() {
	},
  watch: {
    analysis: {
      handler: function () {
        this.updateReport()
      },
    },
  },
};
</script>
<template>
  <div class="container">
    <section class="executive_summary">
      <section class="payment_mode">
        <div style="margin-top: 30px;">
          <h4 style="font-size: 18px; margin-bottom: 10px;">Payment details.</h4>
          <div class="">
            <div class="">
              <ul style="width: 100%;">
                <li v-for="channel, i in payment_mode" :key="i" style="display: flex; justify-content: space-between;">
                  <span class="pad">
                    <span v-if="channel.mode == 0">Cash ({{ channel.count }})</span>
                    <span v-if="channel.mode == 1">Card ({{ channel.count }})</span>
                    <span v-if="channel.mode == 2">Transfer ({{ channel.count }})</span>
                    <span v-if="channel.mode == 3">Other ({{ channel.count }})</span>
                  </span>
                  <span class="pad">&#8358;{{ channel.revenue.toLocaleString() }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>   
      </section> 

      <section class="fulfilment_mode" style="margin-top: 20px">
        <div style="margin-top: 30px;">
          <h4 style="font-size: 18px; margin-bottom: 10px;">Courier company details.</h4>
          <div class="">
            <div class="">
              <ul style="width: 100%;">
                <li v-for="company, i in courier" :key="i" style="display: flex; justify-content: space-between;">
                  <span v-if="company.company != 'NA'" class="pad">{{ company.company }} ({{ company.count }})</span>
                  <span v-if="company.company != 'NA'" class="pad">&#8358;{{ company.total_fees.toLocaleString() }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>   
      </section> 
    </section>
  </div>
</template>

<style scoped>
* {
  color: #143E32;
  box-sizing: border-box;
}
ul {
  padding: 0;
}
.container {
  margin-top: 20px;
  border-top: #C4DBD5 1px solid;
  padding: 20px;
}
 table {
  margin-top: 5px;
  border: #C4DBD5 1px solid;
  border-radius: 8px;
  border-spacing: 0;
 }
th {
  background: #FFF;
  text-align: left;
  border-bottom: 1px solid #C4DBD5;
  border-right: 1px solid #C4DBD5;
}
th:first-of-type {
  border-radius: 8px 0 0 0;
}
th:last-of-type {
  border-radius: 0 8px 0 0;
  border-right: none;
}
th, tr td {
  padding: 10px 7px;
}
td {
  border-bottom: 1px solid #C4DBD5;
  border-right: 1px solid #C4DBD5;
}
table tr:last-of-type td {
  border-bottom: none;
}
table tr td:last-of-type {
  border-right: none;
}
table tr:last-of-type td:first-of-type {
  border-radius: 0 0 0 8px;
}
table tr:last-of-type td:last-of-type {
  border-radius: 0 0 8px 0;
}
tr {
  background: #F5FCF4;
}
.pad {
  padding: 7px 0;
}
</style>
