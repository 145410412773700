<script>
import { mapGetters } from "vuex";
import {
  createPaystackCustomer,
  fetchOrderItems,
  paystackInvoiceRequest,
  updateCustomer,
  updateOrder,
  deleteOrder,
} from "@/services/apiServices";
import { UPDATE_SALE, DELETE_ORDER, ACTIVE_ORDER } from "@/store/mutationTypes"
import { jsPDF } from 'jspdf'


import * as mutationTypes from "@/store/mutationTypes";

// import dustBinIcon from "@/assets/Images/dustbin.svg";
import OrderItems from "@/components/Order/OrderItems";
import hamMenu from "@/assets/Images/hamMenu.svg";
import OrderMenu from "@/components/Order/OrderMenu";

// import truckIcon from "@/assets/Images/grayTruck.svg";
// import cancelStroke from "@/assets/Images/cancelStroke.svg";
// import chainLink from "@/assets/Images/chainLink.svg";
// import cancelRed from "@/assets/Images/cancelRed.svg";
// import copyIcon from "@/assets/Images/copyIcon.svg";
// import sheet from "@/assets/Images/sheet.svg";
// import sourceIcon from "@/assets/Images/sourceIcon.svg";
// import DeleteConfirmationPopUp from "@/components/DeleteConfirmationPopUp.vue";
// import PopUpScreen from "../PopUpScreen.vue";
// import penIcon from "@/assets/Images/penGray.svg";
// import moreIcon from "@/assets/Images/more.svg";
// import grayCalendar from "@/assets/Images/grayCalendar.svg";
// import UpdateScreen from "./UpdateScreen.vue";
// import AddPayment from "./AddPayment.vue";
// import threeDots from "@/assets/Images/threeDotsGray.svg";
// import recycle from "@/assets/Images/recycle.svg";
// import share from "@/assets/Images/share.svg";
// import grayCancel from "@/assets/Images/grayCancel.svg";
// import ShareInvoice from "./ShareInvoice.vue";
// import MarkComplete from "./MarkComplete.vue";
// import OrderIssue from "./OrderIssue.vue";
// import AddIssue from "./AddIssue.vue";
// import IssueChat from "./IssueChat.vue";
// import SetStatus from "./SetStatus.vue";
// import ReturnOrder from "./ReturnOrder.vue";
// import ConfirmReturn from "./ConfirmReturn.vue";
// import ReturnApproval from "./ReturnApproval.vue";
// import ReturnedItems from "./ReturnedItems.vue";
export default {
  name: "OrderDetails",
  props: ["order", "index", "displayMenu"],
  data() {
    return {
      hamMenu,
      // truckIcon,
      // penIcon,
      // moreIcon,
      // cancelStroke,
      // dustBinIcon,
      // chainLink,
      // cancelRed,
      // sheet,
      // share,
      // copyIcon,
      // grayCalendar,
      // threeDots,
      // recycle,
      // grayCancel,
      // deleteIndex: 0, // probably not used here
      // sourceIcon,
      showMenu: false,
      // visiblePopUp: false,
      // showOverlay: false,
      // activeOrder: {},
      order_items: [],
      showItems: false,
      // overlayTitle: "",
      // overlayMessage: "",
      // overlaySubMessage: "",
      // placeholderText: "",
      // textBox: false,
      // isPopUpVisible: false,
      // btnText: "",
      // message: "",
      // headerText: "Date",
      // approvalTitle: "",
      // approvalMessage: "",
      // approvalHeader: "",
      // download: false, // used to make receipt visible for download
      // editOrderDate: false,
      // approvalContent: false,
      // btnOption: false,
      // visibleModal: false,
      // updatePaymentPopUp: false,
      // payment_status: false,
      // no_payment: false,
      // moreList: false,
      // shareInvoiceModal: false,
      // markedModal: false,
      // issueModal: false,
      // issueForm: false,
      // chatModal: false,
      // setStatusPopUp: false,
      // returnOrderModal: false,
      // confirmReturnModal: false,
      // returnApprovalModal: false,
      // returnItemsModal: false,
      
    };
  },
  components: {
    OrderItems,
    OrderMenu,
    // OrderIssue,
    // DeleteConfirmationPopUp,
    // PopUpScreen,
    // UpdateScreen,
    // AddPayment,
    // ShareInvoice,
    // MarkComplete,
    // AddIssue,
    // IssueChat,
    // SetStatus,
    // ReturnOrder,
    // ConfirmReturn,
    // ReturnApproval,
    // ReturnedItems,
  },
  methods: {
    closeMenu() {
      this.showMenu = false
    },
    getOrderItems() {
      this.showItems = !this.showItems;
      if (this.order_items.length < 1) {
        fetchOrderItems(this.order.order_ref).then((res) => {
          this.order_items = res.data;
        });
      }
    },
    toggleMoreOptions() {
      this.$store.commit(ACTIVE_ORDER, this.order)
      this.$emit('menuOverlay')
      this.showMenu = !this.showMenu
      if (this.showMenu) {
        document.addEventListener("click", this.handleClickOutside);
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    getCustomerPSCode() {
      if (this.customer.paystack_code !== undefined) {
        return this.customer.paystack_code;
      } else {
        if (typeof this.customer.email == "undefined") {
          alert("Customer requires an email address to create an invoice.");
          return null;
        }

        let data = {
          email: this.customer.email,
        };

        createPaystackCustomer(data).then((res) => {
          let data = {
            paystack_code: res.data.data.customer_code,
            paystack_id: res.data.data.id,
          };

          updateCustomer(data, this.customer.id).then((res) => {
            this.$store.commit(mutationTypes.UPDATE_CUSTOMER, {
              id: this.customer.id,
              updated_customer: res.data,
            });

            return res.data.paystack_code;
          });
        });
      }
    },
    handleClickOutside(event) {
      if (
        this.$refs.options &&
        this.$refs.hamMenu &&
        !this.$refs.options.contains(event.target) &&
        !this.$refs.hamMenu.contains(event.target)
      ) {
        this.showMenu = false;
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    handleConfirm() {
      this.delete();
      this.showOverlay = false;
    },
    handleCancel() {
      this.showOverlay = false;
      this.isPopUpVisible = false;
      this.visibleModal = false;
      this.markedModal = false;
      this.issueModal = false;
    },
    handlePopUpCancel() {
      this.updatePaymentPopUp = false;
      this.shareInvoiceModal = false;
      this.issueForm = false;
      this.chatModal = false;
      this.returnOrderModal = false;
      this.confirmReturnModal = false;
      this.returnItemsModal = false;
      this.returnApprovalModal = false;
    },
    handleStatusCancel() {
      this.setStatusPopUp = false;
    },
    handleOverlay() {
      this.showOverlay = true;
      this.overlayMessage = "order";
    },
    removeOrder() {
      deleteOrder(this.activeOrder.id);
      this.$store.commit(DELETE_ORDER, this.activeOrder.id);
      alert("order has been deleted");
      this.showOverlay = false;
    },
   
    editOrder(order, action) {
      switch (action) {
        case 0: // mark complete
          this.showMenu = false;
          {
            let data = { fulfilled: 1 };
            updateOrder(data, order.id) // assuming you can only mark fulfilled
              .then((res) => {
                this.$store.commit(UPDATE_SALE, res.data);
                // also update order items
                // give warning that it's not reversible - unless returned
                // except an admin - roles
                alert("Order updated to complete");
              });
            break;
          }
        case 1: // delete order
          this.activeOrder = order;
          this.showOverlay = true;
          this.overlayTitle = "Delete order";
          this.overlayMessage =
            "This will permanently delete this order and all associated data";
          this.overlaySubMessage = "";
          break;
        // case 2: // update paid amount
        //   null
        //   break;
        // case 3: // cancel
        //   null
        //   break;
        // case 4:
        //   null
        //   break;
        // default:
        //   null
      }
    },
    editDate() {
      this.editOrderDate = true;
      this.overlayTitle = "Edit order date";
      this.isPopUpVisible = true;
      // this.showOverlay = true
      this.textBox = true;
      this.placeholderText = "Mar 22, 2024";
      this.btnText = "Send for approval";
      this.headerText = "Date";
      this.btnOption = true;
    },
    updatePayment() {
      this.visibleModal = true;
      this.payment_status = true;
    },
    addPaymentPop() {
      this.updatePaymentPopUp = true;
    },
    markedComp() {
      this.markedModal = true;
    },
    sendApprovalRequest() {
      (this.editOrderDate = false),
        (this.approvalContent = true),
        (this.isPopUpVisible = true);
      (this.approvalTitle = "Date change request"),
        (this.approvalMessage =
          "Do you want to allow change of the for the order below"),
        (this.approvalHeader = "Approve change of date");
      this.btnOption = false;
    },
    moreGroup() {
      this.moreList = true;
    },
    nextPage() {
      (this.returnOrderModal = false), (this.confirmReturnModal = true);
    },
    previousPage() {
      (this.returnOrderModal = true), (this.confirmReturnModal = false);
    },
    approval() {
      this.returnApprovalModal = true;
    this.confirmReturnModal = false;
    },
    mainPage(){
      this.returnOrderModal = false;
      this.returnItemsModal = true;
    },
    subPage() {
      this.returnOrderModal = true;
      this.returnItemsModal = false;
    },
     goBack() {
       this.confirmReturnModal = true;
       this.returnApprovalModal = false;
    },
    showLess() {
      this.moreList = false;
    },
    showChatBox() {
      this.chatModal = true;
    },
    showOrderReturnModal() {
      this.returnOrderModal = true;
    },
    showIssue() {
      this.issueModal = true;
    },
    showIssueForm() {
      this.issueForm = true;
    },
    showSetStatusPopUp() {
      this.setStatusPopUp = true;
    },
    dlReceipt(type) {
      if (type == 'receipt') {
        this.download = true
        const doc = new jsPDF()
        const receipt = document.getElementById('receipt')
        doc.html(receipt, {
          callback: function(doc) {
            doc.save('receipt.pdf')
          },
          x: 15,
          y: 15,
          width: 170, // target width in PDF document
          windowWidth: 650 // window width in CSS pixels
        })
      } else {
        // generates paystack payment link //
        // shareInvoiceLink() {
        this.toggleMoreOptions();
        let PSCode = this.getCustomerPSCode();

        if (PSCode) {
          var line_items = [];
          // if (this.order_items.length < 1) {
          fetchOrderItems(this.order.order_ref).then((res) => {
            this.order_items = res.data;

            // was trying to do everything below outside the fetchOrderItems' .then
            // but it seemed they ran assynchronsously
            for (let i = 0; i < this.order_items.length; i++) {
              let item = {
                name: this.order_items[i].product_name,
                amount: (this.order_items[i].price_sold * 100).toString(),
                quantity: this.order_items[i].qty,
              };
              line_items.push(item);
            }

            line_items.push({
              name: "Delivery fee",
              amount: (this.order.shipping * 100).toString(),
              quantity: 1,
            });

            let data = {
              description: "Invoice for order " + this.order.order_ref,
              line_items: line_items,
              customer: PSCode,
              due_date: "2020-07-08",
            };

            paystackInvoiceRequest(data)
              .then((res) => {
                alert("Invoice for order x has been sent to user");
                console.log(res)
                // mark as invoice sent
              })
              .catch((err) => {
                console.log("error", err.response.data);
              });
          });
          // }
        }
        this.shareInvoiceModal = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      customers: "getCustomers",
    }),
    customer() {
      try {
        let buyer = this.customers.find(
          (customer) => customer.id == this.order.customer
        );
        let customer = [];
        buyer.first_name ? (customer.first_name = buyer.first_name) : "";
        buyer.last_name ? (customer.phone = buyer.phone) : "";
        buyer.email ? (customer.email = buyer.email) : "";
        buyer.paystack_code
          ? (customer.paystack_code = buyer.paystack_code)
          : "";
        buyer.paystack_id ? (customer.paystack_id = buyer.paystack_id) : "";
        buyer.id ? (customer.id = buyer.id) : "";
        return customer;
      } catch {
        return { first_name: "Anonymous", phone: "" };
      }
    },
    order_date() {
      const ms_per_day = 1000 * 60 * 60 * 24;
      let months = [
        "Jan ",
        "Feb ",
        "Mar ",
        "Apr ",
        "May ",
        "Jun ",
        "Jul ",
        "Aug ",
        "Sep ",
        "Oct ",
        "Nov ",
        "Dec ",
      ];
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const ty = ["Today", "Yesterday"];
      let order_year = parseInt(this.order.order_date.substring(0, 4));
      let order_month = parseInt(this.order.order_date.substring(5, 7)) - 1;
      let order_date = parseInt(this.order.order_date.substring(8, 10));
      const today = new Date();
      const this_day = today.getDay(); // position in week
      const utc1 = Date.UTC(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      const utc2 = Date.UTC(order_year, order_month, order_date);
      let difference = Math.floor((utc1 - utc2) / ms_per_day);
      if (difference > 6) {
        return months[order_month] + " " + order_date;
      } else if (difference > 1) {
        if (this_day - difference < 0) {
          //  months[order_month] +  " - " +
          return months[order_month] + " " + order_date;
        }
        return (
          days[this_day - difference] + ", " + months[order_month] + order_date
        );
      } else {
        return ty[difference];
      }
    },
    ordinal_suffix() {
      let order_date = parseInt(this.order.order_date.substring(8, 10));
      var j = order_date % 10,
        k = order_date % 100;
      if (j == 1 && k != 11) {
        return "st";
      }
      if (j == 2 && k != 12) {
        return "nd";
      }
      if (j == 3 && k != 13) {
        return "rd";
      }
      if (this.order_date == "Today" || this.order_date == "Yesterday") {
        return "";
      }
      return "th";
    },
  },
  watch: {
    displayMenu() {
      this.displayMenu == false ? this.showMenu = false : ''
    }
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<template>
  <div class="bdy">
    <div class="container_main">
      <div class="item_card">
        <div class="card_top" @click="getOrderItems(order)">
          <div class="card_top_details">
            <!-- <img :src="i.image" alt="getItemById(i).name" /> -->
            <div class="ct_info">
              <h1>{{ customer.first_name }}</h1>
              <small>
                {{ order_date }}
                {{
                  order_date == "Today" || order_date == "Yesterday"
                    ? ""
                    : parseInt(order.order_date.substring(0, 4))
                }}
                &#x2022;
                {{ order.order_ref }}
              </small>
            </div>
          </div>
          <div class="ct_right">
            <!-- <img :src="profile" alt="users picture" class="profile"> -->

            <!-- <p>&#8358; {{ order.products_total }}</p>
            <p>{{ order.fulfilled }}/{{ order.items_count }}</p>
            <p>Pending</p> -->
            <!-- I want to us a list above, not paragraph -->
            <p>&#8358;{{ Number(order.total_amount).toLocaleString() }}</p>
            <p>{{ order.fulfilled }}/{{ order.items_count }}</p>
            <div class="fulfilled">
              <p v-if="order.fulfilled == 0">Pending</p>
              <p v-else-if="order.fulfilled != 0 && order.fulfilled < order.items_count">Part</p>
              <p v-else-if="order.fulfilled == order.items_count">Complete</p>
              <p v-else-if="order.fulfilled == -1">Cancelled</p>
            </div>
          </div>
        </div>
        <OrderMenu
          v-if="showMenu"
          :order="order"
          @closeMenu="closeMenu"
        />
        <div>
          <div v-if="showItems" class="showItems">
            <OrderItems
              v-for="(item, i) in order_items"
              :key="i"
              :item="item"
            ></OrderItems>
          </div>
          <div class="card_buttom">
            <div class="tags">
              <p class="payment_mode">
                <span v-if="order.payment_status == 1"> Paid</span>
                <span v-else> Unpaid</span>
              </p>
              <!-- <p class="payment_mode">has issues tag</p> -->
              <!-- <p>use red colour to indicte cancelled</p> -->
              <span v-if="order.payment_mode == 1" style="padding: 0; border: none; margin: 0;">
                <p class="payment_mode">
                  <span v-if="order.payment_mode == 0"> Cash</span>
                  <span v-else-if="order.payment_mode == 1"> Card</span>
                  <span v-else-if="order.payment_mode == 2"> Transfer</span>
                  <span v-else-if="order.payment_mode == 3"> Other</span>
                </p>
              </span>
              <p class="payment_mode">
                <span v-if="order.channel == 0">Shop</span>
                <span v-else-if="order.channel == 1">Instagram</span>
                <span v-else-if="order.channel == 2">Whatsapp</span>
                <span v-else-if="order.channel == 3">Website</span>
                <span v-else-if="order.channel == 4">Other</span>
                <span v-else-if="order.channel == 5">Facebook</span>
                <span v-else-if="order.channel == 6">X</span>
              </p>
              <p class="payment_mode">
                <span v-if="order.shipping_mode == false">Delivery</span>
                <span v-else-if="order.shipping_mode == true">Pickup</span>
              </p>
            </div>
            <img
              :src="hamMenu"
              alt="hamMenu"
              @click="toggleMoreOptions"
              ref="hamMenu"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.bdy {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.container_main {
  width: 100%;
}
.container {
  width: 100%;
}
.header_link {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  padding: 0 16px;
  margin-bottom: 8px;
}
.header_link a {
  color: #008060;
}
button {
  background: #008060;
  border: none;
  border-radius: 4px;
}
button > a {
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}
button > a > img {
  margin: 0 4px;
}
.slot_btn {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
}
.sorting-options {
  display: flex;
}
.item_card {
  position: relative;
  margin-bottom: 8px;
  padding: 16px 12px;
  background: #f5fcf4;
  display: flex;
  flex-direction: column;
  border: #c4dbd5 1px solid;
  border-radius: 8px;
}
.card_top {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  border-bottom: #c4dbd5 1px solid;
}
.card_top_details {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.card_top_details > img {
  margin-right: 12px;
}
.card_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.clr {
  width: 45%;
  display: flex;
  flex-direction: column;
}
.clr > label {
  margin: 4px 0;
  color: #002e22;
}
.ct_info > h1 {
  font-size: 16px;
  color: #002e22;
  font-weight: 500;
  margin-bottom: 4px;
}
.ct_info > p {
  font-size: 14px;
  color: #355f53;
  font-weight: 400;
}
.card_buttom {
  margin-top: 8px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tags {
  display: flex;
  width: 80%;
}
.list {
  padding: 8px;
  text-decoration: none;
  border-bottom: #c4dbd5 0.3px solid;
  color: #355f53;
}
.showItems {
  border-bottom: 1px solid #c4dbd5;
  padding: 8px 0;
}
.cancel {
  color: red;
}
.profile {
  width: 32px;
  height: 32px;
  margin-bottom: 4px;
}
.ct_right {
  display: flex;
  flex-direction: column;
  color: #143e32;
  align-items: flex-end;
}
.ct_right > p {
  font-weight: 500;
}
li {
  font-size: 14px;
  color: #002e22;
  list-style: none;
  padding: 8px;
  font-weight: 400;
}
li:focus,
li:hover {
  color: #008060;
  border-bottom: #008060 1px solid;
}
small,
.payment_mode {
  color: #92aaa3;
}
span {
  /* background: white; */
  padding: 4px 8px;
  border: #c4dbd5 1px solid;
  margin-right: 5px;
  border-radius: 16px;
}
.ct_info > p {
  margin: 4px 0;
}
.nested-enter-active,
.nested-leave-active {
  transition: all 0.3s ease-in-out;
}
.nested-leave-active {
  transition-delay: 0.25s;
}

.nested-enter-from,
.nested-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
.nested-enter-active .inner,
.nested-leave-active .inner {
  transition: all 0.3s ease-in-out;
}
.nested-enter-active .inner {
  transition-delay: 0.25s;
}
.nested-enter-from .inner,
.nested-leave-to .inner {
  transform: translateX(30px);
  opacity: 0.001;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.hide {
  display: none;
}
</style>
