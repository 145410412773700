<script>
import { mapGetters } from "vuex"

export default {
  name: "SalesBreakdown",
  props: [
   'breakdown',
   'net_sales',
   'date_range',
   'no_sales',
   'weekly_sales'
  ],
  data() {
    return {
      // Empty samplese required to prevent //
      // non-data errors //
      order_count: 0,
      max_order_week: null,
      max_order_count: 0,
      max_order_dates: '',
      max_order_week_percent: 0,
      product_sales: [
        {
          product: "Empty",
          qty: 0,
          percent: 0,
          amount: 0
        },
        {
          product: "Empty",
          qty: 0,
          percent: 0,
          amount: 0
        },
        {
          product: "Empty",
          qty: 0,
          percent: 0,
          amount: 0
        },
      ],
      orders: [
        {
          unit: "1-7",
        },
        {
          unit: "8-14",
        },
        {
          unit: "15-21",
        },
        {
          unit: "22-28",
        },
        {
          unit: "29-31",
        },
      ],
      customers: [
        {
          first_name: "",
          order_count: 0,
          order_refs: [],
          item_count: 0,
          total_spent: 0,
          percent: 0,
        },
        {
          first_name: "",
          order_count: 0,
          order_refs: [],
          item_count: 0,
          total_spent: 0,
          percent: 0,
        },
        {
          first_name: "",
          order_count: 0,
          order_refs: [],
          item_count: 0,
          total_spent: 0,
          percent: 0,
        },
      ],      
    };
  },
  methods: {
    updateReport() {
      var products = []
      for (let i=0; i < this.breakdown.unique_products.length; i++) {
        let index = this.inventory.findIndex(product => product.id == this.breakdown.unique_products[i].id)
        try {var name = this.inventory[index].product_name}
        catch {name = "Unknown"}

        let percent = (this.breakdown.unique_products[i].amount / this.net_sales * 100).toFixed(1)

        let product = {
          'product': name,
          'qty': this.breakdown.unique_products[i].qty,
          'percent': percent,
          'amount': this.breakdown.unique_products[i].amount,
        }
        products.push(product)
      }
      this.product_sales = products


      // consider getting this other ways //
      this.orders = this.breakdown.sales_count
      let order_count = 0
      for (let i=0; i < this.orders.length; i++) {
        order_count +=  this.orders[i].count
      }
      this.order_count = order_count

      var max = 0
      var heights = []
      for (let i=0; i < this.orders.length; i++) {
        let height = (this.orders[i].count / this.order_count * 100).toFixed(1)
        height > max ? max = height : ""
        if (this.orders[i].count > this.max_order_count) {
          this.max_order_count = this.orders[i].count
          this.max_order_week = i + 1
          this.max_order_dates = this.orders[i].unit
          this.max_order_week_percent = height
        }
        heights.push(height)
      }

      var ratio = (100 / max).toFixed(3)
      if (ratio > 1) {
        for (let i=0; i < this.orders.length; i++) {
          let id = this.orders[i].unit + '_ord'
          let unit = document.getElementById(id)
          unit.style.height = heights[i] * ratio + "%"
        }
      } else {
        for (let i=0; i < this.orders.length; i++) {
          let id = this.orders[i].unit + '_ord'
          let unit = document.getElementById(id)
          unit.style.height = heights[i] + "%"
        }
      }

      var patrons = this.breakdown.customers
      for (let i in patrons) {
        patrons[i].percent = (patrons[i].total_spent / this.net_sales * 100).toFixed(1)
      }

      patrons.sort((a, b) => (
        a.total_spent < b.total_spent ? 1 : b.total_spent < a.total_spent ? -1 : 0))

      this.customers = patrons
    }
  },
  computed: {
    ...mapGetters({
      inventory: "getInventory",

    }),
    total_items_sold() {
      return this.breakdown.total_item_count
    },
    unique_products() {
      return this.breakdown.unique_product_count
    },
    avg_customer_spend() {
      return Math.round(this.net_sales / this.customers.length)
    },
    top_three_customer_spend() {
      let total_spent = 0
      this.customers.length > 2
        ?  total_spent = this.customers[0].total_spent + this.customers[1].total_spent + this.customers[2].total_spent
        : this.customers.length > 1
          ? total_spent = this.customers[0].total_spent + this.customers[1].total_spent
          : total_spent = this.customers[0].total_spent
      
      return total_spent
    },
    top_three_customer_percent() {
      return Math.round(this.top_three_customer_spend / this.net_sales * 100)
    },
    top_three_product_percent() {
      return Math.round((this.product_sales[0].amount + this.product_sales[1].amount + this.product_sales[2].amount) / this.net_sales * 100)
    },
    items_per_order() {
      return (this.total_items_sold / this.order_count).toFixed(1)
    },
  },
  watch: {
    breakdown: {
      handler: function () {
        if (this.breakdown.total_item_count > 0) {
          this.report = this.sales_report
          this.updateReport()
        }        
      },
    },
  },
};
</script>
<template>
  <div class="container">
    <section class="executive_summary">
      <h2 style="font-size: 28px">2. Sales breakdown</h2>
      <h3 class="section_title">Sales by products sold</h3>
      <p class="prose">
        You sold a total of {{ total_items_sold }} items this month, consisting of {{ unique_products }} products. Your three best sellers were {{ product_sales[0].product }}, {{ product_sales[1].product }}, and {{ product_sales[2].product }}, contributing 30% of your sales. 
        <span v-if="no_sales">{{ no_sales[0].product_name }} 
          <span v-if="no_sales.length > 1">and {{ (no_sales.length) - 1 }} other product<span v-if="no_sales.length > 2">s</span> did not have any sale<span v-if="no_sales.length > 2">s</span>.</span>
        </span>
      </p>
      <div style="margin-top: 30px;">
        <h4>Products' sales performance.</h4>
        <span>{{ date_range }}</span>
        <div class="">
          <div class="">
            <table style="width: 100%;">
              <tr>
                <th>Product</th>
                <th>Qty</th>
                <th>% of sales</th>
                <th>Sales</th>
              </tr>
              <tr v-for="product, i in product_sales" :key="i">
                <td>{{ product.product }}</td>
                <td>{{ product.qty }}</td>
                <td>{{ product.percent }}%</td>
                <td>&#8358; {{ product.amount.toLocaleString() }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <h3 class="section_title" style="margin-top: 50px;">Sales by order numbers</h3>
      <p class="prose">
        You earned a total of {{ order_count }} orders this month with an average of {{ items_per_order }} item<span v-if="items_per_order > 1">s</span> per order, and an average order value of &#8358;{{ Math.round(net_sales/order_count).toLocaleString() }}. Week {{ max_order_week }} ({{ max_order_dates }}st) was your busiest with {{ max_order_count }} orders, accounting for {{ max_order_week_percent }}% of your sales in the period.
      </p>
      <div style="margin-top: 30px;">
        <h4>Break down of orders.</h4>
        <div class="mini_container">
          <div class="sales_graph">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <div>
                <h4 style="display: inline-block; margin-top: 5px;">
                  {{ date_range }}
                </h4>
              </div>
              <div class="select_box">
                <span>Orders ^</span>
              </div>
            </div>

            <ul style="">
              <li
                v-for="order, i in orders" :key="i"
                style="position: relative; display: flex; flex-direction: column; height: 160px; width: 20px;
                align-items: center;"
              >
                <div :id="order.unit + '_ord'" class="graph_bar"><!-- height of bar-->
                  <span v-if="order.count" class="graph_data"><!-- orders -->{{ order.count }}
                  </span>
                  <span v-else class="graph_data"><!-- no orders --> 0 </span>
                  <span class="graph_colour"><!-- graph colour --></span>
                </div>
                <span style="position: absolute; bottom: -25px; width: 45px; text-align: center; opacity: 0.8;">{{ order.unit }}</span>
              </li>
            </ul>
          </div>
        </div>        
      </div>

      <h3 class="section_title" style="margin-top: 50px;">Sales by customer activity</h3>
      <p class="prose">
        You were patronised by {{ customers.length }} customers this month. They spent an average of &#8358;{{ avg_customer_spend.toLocaleString() }} each but your top three were {{ customers[0]['first_name'] }}, <span v-if="customers.length > 1">{{ customers[1]['first_name'] }}</span>, and <span v-if="customers.length > 2">{{ customers[2]['first_name'] }}</span>, who purchased a combined &#8358;{{ top_three_customer_spend.toLocaleString() }} worth of goods, making up {{ top_three_customer_percent }}% of your sales.
      </p>
      <div style="margin-top: 30px;">
        <h4>Break down of customer activity.</h4>
        <div class="">
          <div class="">
            <table style="width: 100%;">
              <tr>
                <th style="width: 100px;">Name</th>
                <th>No of orders</th>
                <th>Amount spent</th>
                <th>% of sales</th>
              </tr>
              <tr v-for="customer, i in this.customers" :key="i">
                <td>
                  <span class="no_wrap">{{ customer.first_name }}</span> 
                </td>
                <td>{{ customer.order_count }}&nbsp;<span class="light" style="margin-top: 0;">orders</span> 
                </td>
                <td>
                  <span v-if="customer.total_spent > 999999">
                    &#8358;{{ (Math.round(customer.total_spent/1000000)).toLocaleString() }} M
                  </span>

                  <span v-else-if="customer.total_spent > 100000">
                    &#8358;{{ (Math.round(customer.total_spent/1000)).toLocaleString() }} K
                  </span>

                  <span v-else>&#8358;{{ customer.total_spent.toLocaleString() }} </span>
                </td>
                <td>
                  <span style="display: inline-block; margin-top: 5px;">{{ customer.percent }}%</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>      
    </section>
  </div>
</template>

<style scoped>
* {
  box-sizing: border-box;
  color: #143E32;
}
ul {
  padding: 0;
}
.container {
  margin-top: 20px;
  border-top: #C4DBD5 1px solid;
  padding: 20px;
  padding-top: 50px;
}
.section_title {
  margin-top: 20px;
  text-decoration: underline;
}
.prose {
  margin-top: 10px;
  font-size: 18px;
  word-spacing: 2px;
  line-height: 24px;
}
 table {
  margin-top: 5px;
  border: #C4DBD5 1px solid;
  border-radius: 8px;
  border-spacing: 0;
 }
th {
  background: #FFF;
  text-align: left;
  border-bottom: 1px solid #C4DBD5;
  border-right: 1px solid #C4DBD5;
}
th:first-of-type {
  border-radius: 8px 0 0 0;
}
th:last-of-type {
  border-radius: 0 8px 0 0;
  border-right: none;
}
th, tr td {
  padding: 10px 7px;
}
td {
  border-bottom: 1px solid #C4DBD5;
  border-right: 1px solid #C4DBD5;
}
table tr:last-of-type td {
  border-bottom: none;
}
table tr td:last-of-type {
  border-right: none;
}
table tr:last-of-type td:first-of-type {
  border-radius: 0 0 0 8px;
}
table tr:last-of-type td:last-of-type {
  border-radius: 0 0 8px 0;
}
tr {
  background: #F5FCF4;
}
.light {
  opacity: 0.7;
  display: inline-block;
  margin-top: 5px;
}
.no_wrap {
  display: inline-block; width: 100px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
}
.mini_container {
  width: 100%;
  background: #F5FCF4;
  border: #C4DBD5 1px solid;
  border-radius: 8px;
  padding: 15px 0;
  margin-top: 5px
}
.flex {
  width: 15px;
}
.sales_graph {
  padding: 0 20px;
}
.sales_graph ul {
  margin-top: 25px;
  display: flex; padding: 0 30px 20px; justify-content: space-between;
 }
 .graph_bar {
  display: flex; flex-direction: column; align-items: center; margin-top: auto; min-height: 2px; width: 100%;
 }
 .graph_data {
  display: block; margin-top: -20px; text-align: center;
 }
 .graph_colour {
  background: #008060; width: 100%; height: 100%; border-radius: 5px;
 }
.select_box {
  height: 34px;
  background: #FFF;
  border: #C4DBD5 1px solid;
  border-radius: 5px;
}
.select_box span {
  display: block;
  padding: 7px;
}
</style>
