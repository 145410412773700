<script>
import { mapGetters } from "vuex";
import { fetchOrderItems } from '@/services/apiServices'
// import { DISPLAY_APP_NAV } from "@/store/mutationTypes"

export default {
  name: "OrderReceipt",
  data() {
    return {
      order: {
        shipping_mode: false,
        shipping_price: 0,
        order_ref: null,
      },
      order_items: {},
      customer: {
        'first_name': null,
      }
    }
  },
  methods: {
    updateCustomer() {
      let id = this.customers.findIndex(customer => customer.id == this.order.customer)
      this.customer = this.customers[id]
    },
    updateItems() {
      fetchOrderItems(this.order.order_ref).then((res) => {
      this.order_items = res.data
      })
    }
  },
  mounted() {
    // this.$store.commit(DISPLAY_APP_NAV, false)
    this.updateCustomer
    this.updateItems()
  },
  unmounted(){
    // this.$store.commit(DISPLAY_APP_NAV, true)
  },
  computed: {
    ...mapGetters({
      this_order: 'getOrder',
      customers: "getCustomers",
    }),
    order_date() {
      if (this.order != null) {
        return this.order.order_date
      } else {
        return new Date()
      }
    }
  },
  watch: {
    this_order() {
      this.order = this.this_order
      this.updateCustomer()
      this.updateItems()
    }
  }
};
</script>
<template>
  <div id="receipt">
    <div class="container">
      <div>
        <span style="display: block; font-weight: bold;">{{ (new Date(order_date)).toDateString() }}</span>
        <span style="color: #4CAF50; font-weight: bold">{{ order.order_ref }}</span>
      </div>
      
      <div
        style="margin-top: 20px; padding: 10px; background: rgba(200, 200, 200, 0.1); border: 0.5px solid rgba(0, 0, 0, 0.1); border-radius: 4px; "
      >
        <span v-if="order.shipping_mode == false">
          <p style="margin-bottom: 10px;">Order should be delivered to:</p>
          <p class="address">
            <span style="font-weight: bold; margin-bottom: 5px;">{{ customer.first_name }} {{ customer.last_name }}</span>
            <span>{{ customer.line1 }}</span>
            <span>{{ customer.line2 }}</span>
            <span>{{ customer.city }}</span>
            <span>{{ customer.state }}</span>
            <span style="font-weight: bold; margin-top: 5px;">{{ customer.phone }}</span>
          </p>
        </span>
        <span v-else>
          <p style="margin-bottom: 10px;">Order to be picked up by:</p>
          <p class="address">
            <span style="font-weight: bold; margin-bottom: 5px;">{{ customer.first_name }} {{ customer.last_name }}</span>
            <span style="margin-top: 5px;">{{ customer.phone }}</span>
          </p>
        </span>
      </div>
      <h2 style="font-size: 18px; margin: 70px 0 0;">Order details</h2>
      <table style="width: 100%;">
        <tr style="text-transform: uppercase; font-size: 16px;">
          <th style="text-align: left;">Item / service</th>
          <th style="padding-right: 10px;">Qty</th>
          <th>Subtotal</th>
        </tr>
        <tr v-for="item, i in order_items" :key="i">
          <td>
            <span style="text-transform: capitalize; font-weight: bold;">{{ item.product_name }} @ &#8358;{{ item.price_sold.toLocaleString() }}</span>
            <div style="line-height: normal; font-size: 16px;">
              <span class="var" v-if="item.selected_option1 != ''">
                <span>{{ item.var1name }}:</span> {{ item.selected_option1 }}
              </span><span v-if="item.selected_option2"> / </span>
              <span class="var" v-if="item.selected_option2 != ''">
                <span>{{ item.var2name }}: </span>{{ item.selected_option2 }}
              </span>
            </div>
            <span v-if="item.note !=''">Note: {{ item.note }}</span>
          </td>
          <td style="text-align: center;">{{ item.qty }}</td>
          <td>&#8358;{{ Number(item.sub_total).toLocaleString() }}</td>
        </tr>
        <tr>
          <td>Shipping</td>
          <td style="text-align: center;">-</td> 
          <td v-if="order.shipping_mode == false">&#8358;{{ order.shipping_price.toLocaleString() }}</td>
          <td v-else>NA</td>
        </tr>
        <tr style="font-size: 18px; font-weight: bold;">
          <td colspan="2" style="text-transform: uppercase;">Total</td>
          <td>&#8358;{{ Number(order.total_amount).toLocaleString() }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<style scoped>
#receipt {
  padding: 20px;
  /* position: static; */
  /* left: -5000px; */
  /* top: -5000px; */
  /* height: 100% ; */
  /* box-sizing: border-box; */
}
.container {
  /* height: 100vh; */
  padding: 20px;
  background: #FFF;
  color: #445B54;
  word-spacing: 5px;
  line-height: 22px;
  border: 1px solid #D4ECD5;
  border-radius: 8px;
}
.address span {
  display: block;
  line-height: normal;
}
td, th {
  padding: 10px 0;
  vertical-align: baseline;
}
th {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
td {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
tr:nth-last-child(2) td {
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}
table {
  border-collapse: collapse;
}
</style>
