<script>
import CustomHomeHeader from "@/components/CustomHomeHeader.vue"
// import Hero from "@/assets/Images/OrderHero.svg"

import { fetchEodReport } from "@/services/apiServices"

// import EodOverview from "@/components/Reports/EodOverview"
import EodBreakdown from "@/components/Reports/EodBreakdown"
import EodAnalytics from "@/components/Reports/EodAnalytics"

export default {
  name: "EodReport",
  components: {
    CustomHomeHeader,
    // EodOverview,
    EodBreakdown,
    EodAnalytics,
  },
  data() {
    return {
      // Hero,
      date_today: null,
      eod_date: null,
      current_date: null,
      breakdown: {},
      analysis: {},
      net_sales: 0,
      current_month: null,

      string_date: null,
      gross_revenue: 0,
      report: {
        net_sales: 0,
        shipping: 0,
      }

    };
  },
  methods: {
    getEodData(date) {
      var this_date
      switch (date) {
        case 0:
          this_date = this.current_date = this.date_today
          break;
        case 1:
          this_date = this.current_date = this.eod_date
          break;
        default:
          this_date = this.current_date = this.date_today
      }

      fetchEodReport(this_date)
        .then(res => {
          if (res.data.eod_report.overview.net_sales > 0) {
            this.report = res.data.eod_report.overview
            this.gross_revenue = this.report.net_sales + this.report.shipping
            this.breakdown = res.data.eod_report.breakdown
            this.analysis = res.data.eod_report.analysis
          } else {
            this.report = {net_sales: 0,shipping: 0,}
            this.gross_revenue = 0
            this.breakdown = {}
            this.analysis = {}
          }
        })
    },
    setDate() {
      const today = new Date();
      var year = today.getFullYear().toString().slice(-2);
      var month = (today.getMonth() + 1).toString(); // cause month is 0 indexed
      var day = today.getDate().toString();
      let date = "20"+year+'-'+month.padStart(2, "0")+'-'+day.padStart(2, "0")

      if (date < this.eod_date) {
        alert("Please select today or a date in the past")
        this.eod_date = this.current_date
        return false
      } else {
        return true
      }
    }
  },
  computed: {
    period() {
      let periods = []
      let months = [
        'Year-to-date', 'January', 'February', 'March', 'April', 'May', 'June', 
        'July', 'August', 'September', 'October', 'November', 'December'
      ]
      for (let i=0; i < this.current_month + 1; i++) {
        periods.push({'month': months[i]})
      }
      return periods
    }
  },
  watch: {
    eod_date: {
      handler: function () {
        this.setDate() ? this.getEodData(1) : null
      },
    },
  }, 
  mounted() {
    const today = new Date()
    this.date_today = this.eod_date = today.toISOString().split('T')[0]
    this.current_month = today.getMonth()
    this.getEodData(0)
  }
};
</script>
<template>
  <CustomHomeHeader text="Sales report" link="Reports Index" />

  <div style="margin-top: 10px; padding: 20px;">
    <input v-model="eod_date" type="date" required 
      style="position: absolute; right: 0; margin-right: 20px;" />
  </div>
  <div>
    <CustomHomeHeader text="Sales report" link="Reports Index" />
    <div class="container">
      <section class="executive_summary">
        <h2 style="font-size: 26px; text-align: center;">End-of-Day Report
          <span style="display: block; font-size: 22px; font-weight: normal">
            {{ (new Date(this.eod_date)).toDateString() }} 
          </span>
        </h2>

        <div style="margin-top: 30px;">
          <div class="">
            <div class="flex"></div>
            <div class="sales_summary">
              <h3>&#8358; {{ report.net_sales.toLocaleString() }} <span>Net sales</span> </h3>
              <ul class="key" style="border-top: none;">
                <li><span class="key_colour" style="background: #008060;"></span><p class="key_text">Gross revenue</p></li>
                <li><p class="key_value">&#8358; {{ this.gross_revenue.toLocaleString() }}</p></li>
              </ul>
              <ul class="key">
                <li><span class="key_colour" style="background: #6F847E;"></span><p class="key_text">Shipping</p></li>
                <li><p class="key_value">- &#8358; {{ report.shipping.toLocaleString() }}</p></li>
              </ul>
              <ul class="key">
                <li><span class="key_colour" style="background: #D0E9DE;"></span><p class="key_text">Discounts</p></li>
                <li><p class="key_value">-&#8358; {{ 0 }}</p></li>
              </ul>
              <ul class="key">
                <li><span class="key_colour" style="background: #F5FCF4;"></span><p class="key_text">Refunds</p></li>
                <li><p class="key_value">-&#8358; {{ 0 }}</p></li>
              </ul>
            </div>
            <div class="flex"></div>
          </div>
        </div>
      </section>
    </div>



    <!-- <EodOverview :sales_report="this.eod_report" /> -->
    <EodAnalytics :analysis="this.analysis" />
    <EodBreakdown :breakdown="breakdown" :net_sales="report.net_sales" :shipping="report.shipping" />
  </div>
  <!-- <div v-else>
    You do not have any sales from {{ date_range }}
  </div> -->
  <div style="height: 100px;"></div>
</template>

<style scoped>
* {
  color: #143E32;
  box-sizing: border-box;
}
ul {
  padding: 0;
}
.container {
  padding: 20px;
}
.executive_summary {
  margin-top: 20px;
}
.sales_summary h3 {
  font-size: 24px;
  text-align: center;
}
.sales_summary h3 span {
  display: block;
  font-size: 16px;
  font-weight: lighter;
}
.sales_bar {
  width: 100%;
  height: 17px;
  border-radius: 5px;
  margin-top: 10px;
  /* background: #DAF1DD; */
}
.sales_bar li {
  display: inline-block;
  width: 20px;
  height: 100%;
  border-radius: 5px;
  margin-right: 5px;
}
.mini_container {
  width: 100%;
  background: #F5FCF4;
  border: #C4DBD5 1px solid;
  border-radius: 8px;
  padding: 15px 0;
  margin-top: 5px
}
.flex {
  width: 15px;
}
.key {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding-top: 15px;
  border-top: #C4DBD5 1px solid;
}
.key_colour {
  display: inline-block;
  width: 17px;
  height: 17px;
  border-radius: 5px;
}
.key_text, .key_colour {
  display: inline-block;
}
.key_text {
  margin-left: 5px;
  vertical-align: top;
}
</style>