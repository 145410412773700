<template>
   <div class="header_main">
            <img :src="returnHome" alt="return home arrow" class="arr" @click="$router.back()" />
        <h1>{{ text }}</h1>
    </div>
</template>

<script>
import returnHome from "@/assets/Images/returnHome.svg";
export default {
  props: {
    text: {
      type: String,
      required: true
    }
  },
  data(){
    return{
      returnHome
    }
  }
  
};
</script>
<style scoped>
.header_main {
    background: #F0F5EF;
    position: fixed;
    top: 0;
    z-index: 10000;
    width: 100%;
    padding: 10px 16px;
    border-bottom: 1px solid rgba(53, 95, 83, 0.5);
    display: flex;
    align-items: center;
}

.arr {
    padding: 4px;
    background-color: rgba(53, 95, 83, 0.5);
    border-radius: 4px;
    margin-right: 8px;
}
h1 {
    font-weight: 500;
    font-size: 18px;
    color: rgba(0, 46, 34, 1);

}
</style>