<script>
// import Checkbox from "@/components/CheckBox.vue";

import {
  fetchOrderItems,
  updateFulfilled
} from "@/services/apiServices";

export default {
  name: 'MarkComplete',
  components: {
    // Checkbox,
  },
  props: {
    order: [],
  },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    selectItem(id) {
      updateFulfilled(id)
        .then(res => {
          res.data == '' ? '' : ''
        })
    }
  },
  mounted() {
    fetchOrderItems(this.order.order_ref).then(res => {
      this.items = res.data
    })
  }
};
</script>
<template>
  <!-- Unique content for MarkComplete -->
   
  <!-- Would be ideal to show order number and customer name here -->
  <div class="main-text">
    <div style="display: flex; flex-direction: column; overflow: scroll;">
      <div class="subject">
        <h2>Order items</h2>
        <p style="color: #008060;">Mark all</p>
      </div>

      <div class="order_content" v-for="item, i in items" :key="i">
        <div style="display: flex;">
          <input type="checkbox" @click="selectItem(item.id)">
          <!-- <Checkbox @click="selectItem(item.id)" :ref="'ref_' + item.id" /> -->
          <div style="display: flex;">
            <div class="orderItemsImg">
              <div v-if="item.image_url">
                <img
                  v-if="baseUrl == 'http://127.0.0.1:8080'"
                  :src="'http://127.0.0.1:8000' + item.image_url"
                  alt="img"
                />
                <img v-else :src="item.image_url" alt="img" />
              </div>
              <div v-else>
                <img src="@/assets/Images/default_product_image.png" alt="img" />
              </div>
            </div>

            <div style="margin-left: 10px;">
              <h2>{{ item.product_name }}</h2>
              <p>&#8358; {{ item.price_sold.toLocaleString() }}</p>
            </div>
          </div>
        </div>
        <p :style="{
            padding: '5px 10px',
            borderRadius: '24px',
            background: 'white',
            border: '1px solid #c4cdc5',
            color: '#92AAA3',
          }"
        >
          {{ item.qty }} unit<span v-if="item.qty > 1">s</span>
        </p>
      </div>
    </div>
  </div>
  <!-- End, unique content -->
</template>

<style scoped>
.subject {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
  align-items: center;
}
h2 {
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 600;
  color: #143e32;
}
p {
  color: #355f53;
}
.main-text {
  display: flex;
  padding: 16px;
  flex-direction: column;
}
.order_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
</style>
