<script>
import cancel from "@/assets/Images/cancel.svg";

export default {
  name: "changePrice",
  props:[
  'index',
  'product_name',
  'visible',
  ],
  data() {
      return {
      cancel,
      new_price: null,
    };
  },
  methods: {
    close() {
      this.$emit("close");
      this.new_price = null
    },
    updatePrice(i) {
      this.$emit("newPrice", i, this.new_price);
      alert(this.product_name + " price has been updated");
      this.$emit("close");
      this.new_price = null
    },
  },
};
</script>
<template>
  <div class="change_card_container" v-if="visible">
    <div class="cancel_container">
      <div class="cancel">
        <button @click="close()">
          <img :src="cancel" alt="cancel icon" />
        </button>
      </div>
    </div>
    <div class="change_card">
      <h1 class="hOne">Update sale price of {{ this.product_name }}</h1>
      <form action="">
        <label for="">Price</label>
        <input type="number" v-model="this.new_price" class="price_value" />
        <button @click.prevent="updatePrice(index)">Update Price</button>
      </form>
    </div>
  </div>
</template>
<style scoped>
button {
  background: #008060;
  border: none;
  border-radius: 4px;
}

button > a {
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

button > a > img {
  margin: 0 4px;
}

.btn {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.btn > button {
  width: 95%;
  padding: 12.5px 0;
}

.header_main {
  padding: 10px;
  border-bottom: 1px solid #c4dbd5;
  display: flex;
  align-items: center;
}

.next {
  color: #002e22;
}

.arr {
  padding: 4px;
  background-color: rgba(53, 95, 83, 0.5);
  border-radius: 4px;
  margin: 0 8px;
}

.semi-header {
  display: flex;
  margin: 24px 0px;
  align-items: center;
  justify-content: space-between;
  width: 95%;
}

.sorting-options {
  display: flex;
}

.card_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

span {
  display: flex;
  padding: 4px;
  background: white;
  border: 1px solid #c4dbd5;
  border-radius: 4px;
  margin: 0 12px;
}

button {
  color: white;
  font-size: 14px;

  background: #008060;
  border-radius: 4px;
  border: none;
}

a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
}

.optional {
  display: flex;
  justify-content: space-between;
}

.clr {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.clr > label {
  margin: 4px 0;
  color: #002e22;
}

select {
  border: 1px #c4dbd5 solid;
  border-radius: 6px;
  width: 100%;
  padding: 12px;
  margin-bottom: 8px;
  outline: none;
  appearance: none;
  background-image: url("@/assets/Images/select.svg");
  background-size: 25px;
  background-position: right 8px center;
  background-repeat: no-repeat;
  padding-right: 25px;
  background-color: #f5fcf4;
  font-family: "Aeonik";
}

select {
  color: #92aaa3;
}


.card_top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: #c4dbd5 1px solid;
}

.card_top_details {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.card_top_details > img {
  margin-right: 12px;
}

.ct_info > h1 {
  font-size: 16px;
  color: #002e22;
  font-weight: 500;
  margin-bottom: 4px;
}

.ct_info > p {
  font-size: 14px;
  color: #355f53;
  font-weight: 400;
}

.card_buttom {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.card_buttom > p {
  margin: 0 12px;
  width: 40px;
  height: 40px;
  color: #355f53;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #c4dbd5 1px solid;
}

.options {
  position: absolute;
  border-radius: 8px;
  right: 0;
  padding: 12px;
  background: white;
  z-index: 1;
}

.options a {
  padding: 4px;
}

.options a > img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.pen {
  color: #355f53;
}

.thrash {
  color: red;
}
.change_card_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
  z-index: 2;
  -webkit-backdrop-filter: blur(5px);
  background: transparent, rgba(144, 144, 144, 0.1);
  overflow-y: hidden;
}
.change_card,
.cancel_container {
  position: relative;
  z-index: 2;
}

.change_card {
  width: 95%;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 3px 50px 6px rgba(207, 207, 207, 0.75);
}
.change_card h1 {
  padding: 16px;
  color: rgba(0, 46, 34, 1);
  border-bottom: rgba(196, 219, 213, 1) 1px solid;
  font-weight: 500;
  font-size: 18px;
}
.change_card form {
  display: flex;
  flex-direction: column;
  padding: 0 13px;
}
.change_card form > label {
  margin-top: 24px;
}
.change_card form > input {
  padding: 12px;
  border: rgba(196, 219, 213, 1) 1px solid;
  margin-top: 4px;
  border-radius: 6px;
}
.change_card form > button {
  width: 100%;
  color: white;
  background-color: rgba(0, 128, 96, 1);
  border: 6px;
  padding: 12px;
  border: none;
  margin: 24px 0px;
}
.cancel {
  padding: 6.6px;
  border-radius: 8px;
  background-color: white;
  margin-right: 12px;
}

.cancel_container {
  position: relative;
  margin-bottom: 16px;
  z-index: 2;
  display: flex;
  width: 95%;
  justify-content: flex-end;
}

.cancel > button {
  background-color: rgb(53, 95, 83, 0.6);
  color: #355f53;
  opacity: 0.8;
  border: none;
  display: flex;
  padding: 10px;
  width: 100%;
  border-radius: 50%;
}
</style>
