import * as mutationTypes from './mutationTypes'

export default {
  [mutationTypes.EMAIL_VERIFIED](state, data) {
    state.email_verified = data;
  },
  [mutationTypes.DISPLAY_APP_NAV](state, data) {
    state.display_app_nav = data;
  },
  // [mutationTypes.INITIALISE](state) {
  //   if(localStorage.getItem('leyyow')) {
  //     this.replaceState(
  //       Object.assign(state, JSON.parse(localStorage.getItem('leyyow')))
  //     )
  //   }
  // },
  [mutationTypes.LOAD_ORDERS](state, data) {
    state.orders = data
    state.orders.length > 0 ? state.has_sale = true : ''
  },
  [mutationTypes.LOGGED_IN](state, data) {
    state.logged_in = data
  },
  [mutationTypes.LOGOUT](state) {
    state.logged_in = false
    state.account_id = null
    state.customers = []
    state.customer_to_edit = {}
    state.inventory = []
    state.settlement = {}
    state.store = {}
    state.store_slug = ''
    state.orders = []
    state.order = null
    state.nav_position = 0
    state.metrics = {}
    state.sales_report = {}
  },
  [mutationTypes.ADD_TO_CART_MAP](state, id) {
    state.cart_map.push(id)
  },
  [mutationTypes.ACTIVE_ORDER](state, order) {
    state.order = order
  },
  [mutationTypes.DELETE_ORDER](state, id) {
    let index = state.orders.findIndex(order => order.id == id)
    state.orders.splice(index, 1)
  },
  [mutationTypes.DELETE_PRODUCT](state, id) {
    let index = state.inventory.findIndex(product => product.id == id)
    state.inventory.splice(index, 1)
  },
  // [mutationTypes.SAVE_EMAIL](state, data) {
  //   state.unregistered_email = data;
  // },
  [mutationTypes.SAVE_METRICS](state, data) {
    state.metrics = data;
  },
  [mutationTypes.SAVE_SALES_REPORT](state, data) {
    state.sales_report = data;
  },
  [mutationTypes.SAVE_NEW_CUSTOMER](state, data) {
    state.customers.push(data);
  },
  // [mutationTypes.SAVE_SALE](state, data) {
  //   state.orders.push(data)
  // },
  [mutationTypes.SET_CUSTOMER_STATS](state, data) {
    state.customer_stats = data;
  },
  [mutationTypes.SET_CUSTOMER_TO_EDIT](state, data) {
    state.customer_to_edit = data;
  },
  // [mutationTypes.SET_HEADER_SETTINGS](state, data) {
  //   state.component_settings.header_settings = data;
  // },
  // [mutationTypes.SET_NEXT_BTN_DISPLAY](state, data) {
  //   state.component_settings.display_next_button = data;
  // },
  [mutationTypes.SAVE_SELECTED_CUSTOMER](state, data) {
    state.checkout_customer = data;
  },
  [mutationTypes.SAVE_STORE](state, data) {
    state.store = data;
  },
  [mutationTypes.SAVE_CART_MAP](state, data) { // formerly SAVE_CART
    state.cart_map = data; // formerly cart
  },
  [mutationTypes.SAVE_CHECKOUT_CART](state, data) {
    state.cart_checkout = data;
  },
  [mutationTypes.SAVE_STORE_CUSTOMERS](state, data) {
    state.customers = data;
    state.customers.length > 0 ? state.has_customer = true : ''
  },
  [mutationTypes.SAVE_STORE_SLUG](state, data) {
    state.store_slug = data;
  },
  // [mutationTypes.SAVE_SETTLEMENT](state, data) {
  //   state.settlement = data;
  // },
  [mutationTypes.SAVE_ACCOUNT_ID](state, data) {
    state.account_id = data;
  },
  [mutationTypes.SAVE_PRODUCT](state, data) {
    state.inventory.push(data);
  },
  [mutationTypes.SAVE_OWNERS](state, data) {
    state.owners.push(data);
  },
  [mutationTypes.SAVE_INVENTORY](state, data) {
    state.inventory = data;
    state.inventory.length > 0 ? state.has_product = true : ''
  },
  [mutationTypes.SET_NAV_POSITION](state, data) {
    state.nav_position = data;
  },
  // [mutationTypes.SAVE_VISITOR_INVENTORY](state, data) {
  //   state.visitor_inventory = data;
  // },
  [mutationTypes.SET_PRODUCT_TO_EDIT](state, data) {
    state.to_be_editted = data;
  },
  // [mutationTypes.SET_SETTINGS_STATE](state, data) {
  //   state.settings = data;
  // },
  // [mutationTypes.UPDATE_EMAIL_VERIFIED](state, data) {
  //   state.email_verified = data;
  // },
  // [mutationTypes.SET_FILTER_OPTION](state, data) {
  //   state.filter_option = data;
  // },
  [mutationTypes.SAVE_UNPACKED_CART](state, data) {
    state.cart_unpacked = data;
  },
  // [mutationTypes.UNSAVED_CHANGE](state, data) {
  //   state.unsaved_change = data;
  // },
  [mutationTypes.UPDATE_CUSTOMER](state, data) {
    let customer = state.customers.find(customer => customer.id == data.id)
    Object.assign(customer, data.updated_customer);
  },
  [mutationTypes.UPDATE_OPTIONS](state, data) {
    state.variantsOptionsString.variant1 = data.variant1
    state.variantsOptionsString.variant2 = data.variant2
    state.variantsOptionsString.options1 = data.options1
    state.variantsOptionsString.options2 = data.options2
  },
  [mutationTypes.UPDATE_PRODUCT](state, data) {
    let product = state.inventory.find(product => product.id == data.id)
    Object.assign(product, data.updated_product);
  },
  [mutationTypes.UPDATE_SALE](state, data) {
    let order = state.orders.find(order => order.id == data.id)
    Object.assign(order, data);
  },
  [mutationTypes.UPDATE_VARIANTS](state, data) {
    state.variantsOptionsString.priceAndQtyMatch = data
  },
}