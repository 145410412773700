<template>
  <div class="popup">
    <div class="container_holder">
      <div class="cancel_container">
      <div class="cancel">
        <img :src="cancelDark" alt="cancel"  @click="$router.back()">
      </div>
    </div>
    <div class="container">
      <h3>Add customer</h3>
     <div class="popup_content">
       <div class="content">
        <h2>Customer successfully added</h2>
        <p>This customer will be added from the list, and all purchase history will be added.</p>
       <button><RouterLink to="/customers/:origin">Ok</RouterLink></button>
      
      </div>
     </div>
    </div>
    </div>
  </div>
</template>

<script>
import cancelDark from '@/assets/Images/cancelDark.svg'
export default {
  data() {
    return {
      cancelDark
    }
  },
  methods: {
    closePopup() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container_holder{
  margin: 16px;
}
.cancel_container{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 16px;
}
.cancel{
  background-color: white;
   padding: 8px;
   border-radius: 8px;
   margin-right: 12px;
}
h2{
  text-align: center;
  color: #002E22;
      font-size: 20px;
    font-weight: 500;
}
p{
  margin: 10px 0;
  color: #355F53;
}
h3{
  color: #002E22;
padding: 12px;
border-bottom:1px solid #C4DBD5 ;
}
.container {
  background: white;
  border-radius: 5px;
 
}
.content{
  text-align: center;
  display: flex;
  width: 85%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.popup_content{
 display: flex;
  /* justify-content: center; */
  padding: 20px;
  align-items: center;
  flex-direction: column;
}
button{
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  background-color: #008060;
  border: none;
 
 
}
a{
  text-decoration: none;
   color: white;
   font-weight: 500;
}
.cancel img{
  width: 16px;
  height: 16px;
  background: #90a59d;
  padding: 12px;
  border-radius: 50px;
}
</style>
