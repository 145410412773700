<template>
  <div class="popup" v-if="visiblePopUp">
    <div class="container_holder">
      <div class="cancel_container">
        <div class="cancel">
          <img :src="cancelDark" alt="cancel"  @click="closePopup"/>
        </div>
      </div>
      <div class="container">
        <h3>{{ title }}</h3>
        <div class="popup_content">
          <div class="content">
            <img :src="icons" alt="bin" class="bin" />
            <h2>
              {{ message }}
            </h2>
            <p>
              {{ sub_message }}
            </p>
            <div class="delete_container">
              <button class="delete_btn" @click="handleDeleteItem">Delete</button>

            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cancelDark from "@/assets/Images/cancelDark.svg";
import dustBinIcon from "@/assets/Images/dustbin.svg";
export default {
  props: {
    icon: {
      type: String,
},
    visiblePopUp: {
    type: Boolean,
    required: true,
    },
    title: {
      type: String,
    required:false
    },
    message: {
      type: String,
    required:false
    },
    sub_message: {
      type: String,
    required:false
    },
    index: {
      type: Number,
    required:true
    }
},
  data() {
    return {
      cancelDark,
      icons: dustBinIcon

    };
  },
  methods: {
    closePopup() {
      this.$emit("cancel");
    },
    handleDeleteItem() {
      this.$emit("delete", this.index)
      this.closePopup()
    }
  },
};
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  backdrop-filter: blur(5px);
}
.container_holder {
  margin: 16px;
}
.cancel_container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 16px;
}
.cancel {
  background-color: white;
  padding: 6px;
  border-radius: 8px;
  margin-right: 12px;
}
h2 {
  text-align: center;
  color: #002e22;
  font-size: 20px;
  font-weight: 500;
}
p {
  margin: 10px 0;
  color: #355f53;
}
h3 {
  color: #002e22;
  padding: 12px;
  border-bottom: 1px solid #c4dbd5;
}
.container {
  background: white;
  width: 365px;
  border-radius: 5px;
}
.content {
  text-align: center;
  display: flex;
  width: 85%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.popup_content {
  display: flex;
  /* justify-content: center; */
  padding: 20px;
  align-items: center;
  flex-direction: column;
}
.delete_container{
  width: 100%;
}
.delete_container button {
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: none;
  color: white;
  font-weight: 500;
  font-size: 16px;
}
.delete_btn{
    background-color: #ce3c55
}
.cancel_btn{
  background-color: #008060;
}
.cancel img {
  width: 12px;
  height: 12px;
  background: #90a59d;
  padding: 12px;
  border-radius: 50px;
}
.bin {
  width: 25%;
  margin-bottom: 12px;
}

</style>
