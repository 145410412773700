import axios from "axios"
import * as urls from "./urls"

export const apiLogin = (data) => {
	return axios({
		method: "post",
		url: urls.loginUrl,
		data,
	})
}
export const requestPasswordReset = (email) => {
	return axios({
		method: "post",
		url: urls.requestPasswordResetUrl,
		email,
	})
}
export const bulkUploadProducts = (data) => {
	return axios({
		method: "post",
		url: urls.bulkUploadProductsUrl,
		data,
	})
}
export const bulkUploadCustomers = (data) => {
	return axios({
		method: "post",
		url: urls.bulkUploadCustomersUrl,
		data,
	})
}
export const createCampaign = (data) => {
  return axios({
    method: "post",
    url: urls.createCampaignUrl,
    data,
  })
}
export const createProduct = (data) => {
  return axios({
    method: "post",
    url: urls.createProductUrl,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  })
}
export const createStore = (data) => {
  return axios({
    method: "post",
    url: urls.createStoreUrl,
    data,
  })
};
export const createProductFromTemplate = (data) => {
  return axios({
    method: "post",
    url: urls.createProductFromTemplateUrl,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  })
}
export const fetchCampaigns = (store) => {
	axios({
		method: "get",
		url: `${urls.listCampaignsUrl}${store}/`,
	})
}
export const fetchCampaignDetails = (id) => {
	axios({
		method: "get",
		url: `${urls.campaignDetailUrl}${id}/`,
	})
}
export const fethcStoreInventory = (slug) => {
	axios({
		method: "get",
		url: `${urls.inventoryUrl}${slug}/`,
	})
}
export const fetchMetrics = (period) => {
	return axios({
		method: "get",
    url: `${urls.metricsUrl}${period}/`,
	})
}
export const fetchSalesReport = (period) => {
	return axios({
		method: "get",
    url: `${urls.salesReportUrl}${period}/`,
	})
}
export const fetchEodReport = (period) => {
	return axios({
		method: "get",
    url: `${urls.eodReportUrl}${period}/`,
	})
}
export const fetchOrders = () => {
	return axios({
		method: "get",
		url: urls.ordersUrl,
	})
}
export const fetchOrderItems = (id) => {
  return axios({
    method: "get",
    url: `${urls.orderItemsUrl}${id}/`,
  })
}
export const fetchCustomerStats = (id) => {
  return axios({
    method: "get",
    url: `${urls.fetchCustomerStatsUrl}${id}/`,
  })
}
export const joinWaitlist = (data) => {
  return axios({
    method: "post",
    url: urls.joinWaitListUrl,
    data,
  })
}
export const productQty = (id) => {
	axios({
		method: "get",
		url: `${urls.productQtyUrl}${id}/`, // incomplete url - needs query
	})
}
export const productImageUpdate = (data, id) => {
	axios({
    method: "patch",
    url: `${urls.productImageUpdateUrl}${id}/`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
	})
}
export const deleteOrder = (id) => {
	axios({
    method: "delete",
    url: `${urls.deleteOrderUrl}${id}/`,
	})
}
export const deleteProduct = (id) => {
	axios({
    method: "patch",
    url: `${urls.deleteProductUrl}${id}/`,
	})
}
export const saveCustomer = (data) => {
	return axios({
		method: "post",
		url: urls.saveCustomerUrl,
		data
	})
}
export const saveOrder = (data) => {
	return axios({
		method: "post",
		url: urls.saveOrderUrl,
		data
	})
}
export const saveOrderItems = (data) => {
	return axios({
		method: "post",
		url: urls.saveOrderItemUrl,
		data
	})
}
export const signUp = (data) => {
	return axios({
		method: "post",
		url: urls.signUpUrl,
		data
	})
}
export const updateCampaign = (data, id) => {
  return axios({
    method: "patch",
    url: `${urls.updateCampaignUrl}${id}/`,
    data,
  })
}
export const updateCustomer = (data, id) => {
  return axios({
    method: "patch",
    url: `${urls.updateCustomerUrl}${id}/`,
    data,
  })
}
export const updateProduct = (data, id) => {
  return axios({
    method: "patch",
    url: `${urls.updateProductUrl}${id}/`,
    data,
  })
}
export const updateFulfilled = (id) => {
  return axios({
    method: "patch",
    url: `${urls.updateFulfilledUrl}${id}/`,
  })
}
export const updateOrder = (data, id) => {
  return axios({
    method: "patch",
    url: `${urls.updateOrderUrl}${id}/`,
    data,
  })
}
export const verifyWaitlistEmail = (otp, email) => {
  return axios({
    method: "get",
    url: `${urls.verifyWaitlistEmailUrl}${email}/${otp}/`,
  })
}

// Paystack
// import store from "@/store/index"

try {
  // var auth = store.getters.getSettlement.keys.paystack_secret_key
  var auth = 'sk_test_d0b2b7308f3ed315aa5eb70d7b0072e3e543b4fa'
} catch {null}

export const bankList = () => {
  return axios({
    method: "get",
    url: urls.bankListUrl,
    headers: {
      "Authorization": "Bearer " + auth,
    },
  });
};
export const createPaystackCustomer = (data) => {
  return axios({
    method: "post",
    url: urls.createPaystackCustomerUrl,
    data,
    headers: {
      "Authorization": "Bearer " + auth,
    },
  });
};
export const createSubAcc = (data) => {
  return axios({
    method: "post",
    url: urls.createSubAccUrl,
    data,
    headers: {
      "Authorization": "Bearer " + auth,
    },
  });
};
export const paystackInvoiceRequest = (data) => {
  return axios({
    method: "post",
    url: urls.paystackInvoiceRequestUrl,
    data,
    headers: {
      "Authorization": "Bearer " + auth,
    },
  });
};
export const resolveAcc = (bank_code, acc_no) => {
  return axios({
    method: "get",
    url: `${urls.resolveAccUrl}${acc_no}&bank_code=${bank_code}`,
    headers: {
      "Authorization": "Bearer " + auth,
    },
  });
};
export const saveMerchSettlement = (data) => {
  return axios({
    method: "post",
    url: urls.saveBankUrl,
    data,
  });
};
export const userRegCount = () => {
  return axios({
    method: "get",
    url: urls.userRegCountUrl,
  });
};