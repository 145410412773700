<script>
import CustomHomeHeader from "../CustomHomeHeader.vue";
import Order from "@/components/Order/Order";
import OrderReceipt from "@/components/Order/OrderReceipt"
import SummaryBlocks from "@/components/SummaryBlocks.vue";
import plusWhite from "@/assets/Images/plusWhite.svg";
import searchIcon from "@/assets/Images/searchIcon.svg";
import profile from "@/assets/Images/cartoonProfile.svg";
import hamMenu from "@/assets/Images/hamMenu.svg";
import SearchBar from "@/components/SearchBar.vue";
const defaultFilters = [
  { name: "Open" },
  { name: "Unpaid" },
  { name: "Issues" },
  { name: "Returned" },
  { name: "Cancelled" },
  { name: "Closed" },
];

import { mapGetters } from "vuex";

export default {
  name: "OrdersList",
  data() {
    return {
      plusWhite,
      searchIcon,
      defaultFilters,
      profile,
      hamMenu,
      input:"",

      utc1: null,
      issues: 0,
      open: 0,
      orders_today: 0,
      unpaid: 0,
      displayMenu: false,
    };
  },
  methods: {
    menuOverlay() {
      this.displayMenu = !this.displayMenu
      this.$emit('closeMenu')
    }
  },
  components: {
    CustomHomeHeader,
    SummaryBlocks,
    Order,
    OrderReceipt,
    SearchBar,
  },
  mounted() {
    this.ms_per_day = 1000 * 60 * 60 * 24
    const today = new Date();
    this.this_day = today.getDay(); // position in week

    this.utc1 = Date.UTC(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    )
  },
  computed: {
    ...mapGetters({
      orders: "getOrders",
    }),
    stats() {
      // let plural;
      // this.orders.length > 1 ? (plural = "s") : (plural = "");

      return [
        {
          stat: "Today",
          value: this.orders_today + " orders", // also, monetary value and margin
        },
        {
          stat: "Open",
          value: this.open + " orders"
        },
        {
          stat: "Unpaid",
          value: this.unpaid,
        },        {
          stat: "Have issues",
          value: this.issues,
        },
      ];
    },
    filteredList() {
      return this.orders.filter(
        (order) =>
          order.name.toLowerCase().includes(this.input.toLowerCase()) ||
          order.orderNo.toLowerCase().includes(this.input.toLowerCase())
      );
    },
  },
  watch: {
    orders: {
      handler() {
        for (let i=0; i < this.orders.length; i++) {
          this.orders[i].fulfilled == 0 ? this.open ++ : ""
          this.orders[i].issues == true ? this.issues ++ : ""
          this.orders[i].payment_status == 0 ? this.unpaid ++ : ""

          let created_year = parseInt(this.orders[i].order_date.substring(0, 4))
          let created_month = parseInt(this.orders[i].order_date.substring(5, 7)) - 1
          let created_day = parseInt(this.orders[i].order_date.substring(8, 10))

          let utc2 = Date.UTC(created_year, created_month, created_day)
          let difference = Math.floor((this.utc1 - utc2) / this.ms_per_day)
          difference < 1 ? this.orders_today ++ : ""
        }
      },
      deep: true,
    },
  },
};
</script>
<template>
  <div class="container">
    <CustomHomeHeader text="Orders" link="list" />
    <div class="container_main">
      <div v-if="displayMenu" class="menu_overlay" @click="menuOverlay"><!--for exiting menu--></div>
      <div class="main">
        <SummaryBlocks :stats="stats" />
        <div class="sorting-options">
          <SearchBar v-model="input" placeholder="Search for product..." />
          <button>
            <RouterLink :to="{ name: 'AddToCart' }">
              <img :src="plusWhite" alt="a green plus icon" /> Add Order
            </RouterLink>
          </button>
        </div>
        
        <ul class="scroll_header">
          <li
            v-for="(filter, i) in defaultFilters"
            :key="i"
            class="scroll_header_list"
          >
            {{ filter.name }}
          </li>
        </ul>
      </div>
      <Order
        v-for="(order, i) in orders"
        :key="i"
        :order="order"
        :index="i"
        :displayMenu="displayMenu"
        @menuOverlay="menuOverlay"
      ></Order>
      <div style="display: none;">
        <OrderReceipt id="receipt" />
      </div>
    </div>

    <div style="display: block; height: 75px"><!--props page up--></div>
  </div>
</template>
<style scoped>
.container_main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 16px;
}

.container {
  width: 100%;
}
.main {
  width: 100%;
}
.header_link {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  padding: 0 8px;
  margin-bottom: 8px;
}

.header_link a {
  color: #008060;
}

button {
  background: #008060;
  border: none;
  border-radius: 4px;
}

button > a {
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

button > a > img {
  margin: 0 4px;
}

.sorting-options {
  display: flex;
  margin: 24px 0;
  justify-content: space-between;
}
.sorting-options > button {
  width:35%;
 }

.card_top {
  display: flex;
  justify-content: space-between;
  border-bottom: #c4dbd5 1px solid;
}

.card_top_details {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.card_top_details > img {
  margin-right: 12px;
}

.card_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clr {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.clr > label {
  margin: 4px 0;
  color: #002e22;
}

.ct_info > h1 {
  font-size: 16px;
  color: #002e22;
  font-weight: 500;
  margin-bottom: 4px;
}

.ct_info > p {
  font-size: 14px;
  color: #355f53;
  font-weight: 400;
}

.card_buttom {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.card_buttom > p {
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #c4dbd5 1px solid;
}
.profile {
  width: 32px;
  height: 32px;
  margin-bottom: 4px;
}
.ct_right {
  display: flex;
  flex-direction: column;
  color: #143e32;
  align-items: flex-end;
}
.ct_right > p {
  font-weight: 500;
}

.scroll_header {
  display: flex;
  overflow-x: scroll;
  justify-content: space-between;
  border-bottom: #c4dbd5 1px solid !important;
  margin: 16px 0;
  padding: 0;
}
.scroll_header::-webkit-scrollbar {
  display: none;
}

.scroll_header {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
li {
  font-size: 14px;
  color: #002e22;
  list-style: none;
  padding: 8px;
  font-weight: 400;
}

li:focus,
li:hover {
  color: #008060;

  border-bottom: #008060 1px solid;
}

small,
.payment_status {
  color: #92aaa3;
}
span {
  background: white;
  padding: 4px;
  border: #c4dbd5 1px solid;
  margin-right: 12px;
  border-radius: 4px;
}
.ct_info > p {
  margin: 4px 0;
}
.menu_overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 90;
}
</style>
