<script>
import CustomHomeHeader from "@/components/CustomHomeHeader.vue"

import { } from "@/services/apiServices"

export default {
  name: "CustomerReport",
  components: {
    CustomHomeHeader,
  },
  data() {
    return {
      holder: null,
    };
  },
  methods: {
  },
  computed: {
  },
  watch: {
    holder: {
      handler: function () {
      },
    },
  }, 
  mounted() {
  }
};
</script>
<template>
  <CustomHomeHeader text="Sales report" link="Reports Index" />

  <div style="margin-top: 10px; padding: 20px;">
    <input v-model="eod_date" type="date" required 
      style="position: absolute; right: 0; margin-right: 20px;" />
  </div>
  <div>
    <CustomHomeHeader text="Sales report" link="Reports Index" />
    <div class="container">
      <section class="executive_summary">
        <h2 style="font-size: 26px; text-align: center;">Customer Report
          <span style="display: block; font-size: 22px; font-weight: normal">
            {{ 0 }} 
          </span>
        </h2>

        <div style="margin-top: 30px;">
          <div class="">
            <div class="flex"></div>
            <div class="sales_summary">
              <h3>&#8358; {{ 0 }} <span>Net sales</span> </h3>
              <ul class="key" style="border-top: none;">
                <li><span class="key_colour" style="background: #008060;"></span><p class="key_text">Gross revenue</p></li>
                <li><p class="key_value">&#8358; {{ 0 }}</p></li>
              </ul>
              <ul class="key">
                <li><span class="key_colour" style="background: #6F847E;"></span><p class="key_text">Shipping</p></li>
                <li><p class="key_value">- &#8358; {{ 0 }}</p></li>
              </ul>
              <ul class="key">
                <li><span class="key_colour" style="background: #D0E9DE;"></span><p class="key_text">Discounts</p></li>
                <li><p class="key_value">-&#8358; {{ 0 }}</p></li>
              </ul>
              <ul class="key">
                <li><span class="key_colour" style="background: #F5FCF4;"></span><p class="key_text">Refunds</p></li>
                <li><p class="key_value">-&#8358; {{ 0 }}</p></li>
              </ul>
            </div>
            <div class="flex"></div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <!-- <div v-else>
    You do not have any sales from {{ date_range }}
  </div> -->
  <div style="height: 100px;"></div>
</template>

<style scoped>
* {
  color: #143E32;
  box-sizing: border-box;
}
ul {
  padding: 0;
}
.container {
  padding: 20px;
}
.executive_summary {
  margin-top: 20px;
}
.sales_summary h3 {
  font-size: 24px;
  text-align: center;
}
.sales_summary h3 span {
  display: block;
  font-size: 16px;
  font-weight: lighter;
}
.sales_bar {
  width: 100%;
  height: 17px;
  border-radius: 5px;
  margin-top: 10px;
  /* background: #DAF1DD; */
}
.sales_bar li {
  display: inline-block;
  width: 20px;
  height: 100%;
  border-radius: 5px;
  margin-right: 5px;
}
.mini_container {
  width: 100%;
  background: #F5FCF4;
  border: #C4DBD5 1px solid;
  border-radius: 8px;
  padding: 15px 0;
  margin-top: 5px
}
.flex {
  width: 15px;
}
.key {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding-top: 15px;
  border-top: #C4DBD5 1px solid;
}
.key_colour {
  display: inline-block;
  width: 17px;
  height: 17px;
  border-radius: 5px;
}
.key_text, .key_colour {
  display: inline-block;
}
.key_text {
  margin-left: 5px;
  vertical-align: top;
}
</style>