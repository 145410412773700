<script>
import { mapGetters } from "vuex"

export default {
  name: "EodBreakdown",
  props: [
   'breakdown',
   'net_sales',
   'shipping',
   'date_range',
  ],
  data() {
    return {
      order_count: 0,
      product_sales: [],
      orders: [],     
    };
  },
  methods: {
    updateReport() {
      if (this.net_sales > 0) {
        // products //
        var products = []
          for (let i=0; i < this.breakdown.products.length; i++) {
            let index = this.inventory.findIndex(product => product.id == this.breakdown.products[i].id)
            try {var name = this.inventory[index].product_name}
            catch {name = "Unknown"}

            let product = {
              'product': name,
              'qty': this.breakdown.products[i].qty,
              'amount': this.breakdown.products[i].amount,
            }
            products.push(product)
          }
          this.product_sales = products

      // orders //
        var sales = []
        for (let i=0; i<this.breakdown.orders.length; i++) {
          let order = this.breakdown.orders[i]
          let customer = this.breakdown.customers.findIndex(customer => customer.id == order.customer_id)

          let sale = {
            'order_ref': order.order_ref.substr(order.order_ref.length - 6),
            'customer': this.breakdown.customers[customer].first_name,
            'amount': order.total_amount
          }
          sales.push(sale)
        }
        this.orders = sales
      } else {
        this.orders = [{
          'order_ref': '',
          'customer': 0,
          'amount': 0
        }]
        this.product_sales = [{
          'product': '-',
          'qty': 0,
          'amount': 0,
        }]
      }
    }
  },
  computed: {
    ...mapGetters({
      inventory: "getInventory",

    }),
  },
  watch: {
    breakdown: {
      handler: function () {
        this.report = this.sales_report
        this.updateReport() 
      },
      deep: true,
    },
  },
};
</script>
<template>
  <div class="container">
    <section class="executive_summary">
      <div>
        <h4 style="font-size: 18px;">Orders</h4>
        <div class="">
          <div class="">
            <table style="width: 100%;">
              <tr>
                <th style="width: 100px;">Order ref</th>
                <th>Customer</th>
                <th>Amount</th>
              </tr>
              <tr v-for="order, i in orders" :key="i">
                <td>...{{ order.order_ref }}<span class="light" style="margin-top: 0;"></span> 
                </td>
                <td>
                  <span class="no_wrap">{{ order.customer }}</span> 
                </td>
                <td>
                  <span>&#8358;{{ order.amount.toLocaleString() }} </span>
                </td>
              </tr>
              <tr style="font-weight: bold;">
                <td colspan="2">Grand total</td>
                <!-- <td></td> -->
                <td>&#8358;{{ (shipping + net_sales).toLocaleString() }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div style="margin-top: 30px;">
        <h4 style="font-size: 18px;">Products sold</h4>
        <div class="">
          <div class="">
            <table style="width: 100%;">
              <tr>
                <th>Product</th>
                <th>Qty</th>
                <th>Total</th>
              </tr>
              <tr v-for="product, i in product_sales" :key="i">
                <td>{{ product.product }}</td>
                <td>{{ product.qty }}</td>
                <td>&#8358;{{ product.amount.toLocaleString() }}</td>
              </tr>
              <tr style="font-weight: bold;">
                <td colspan="2">Grand total</td>
                <!-- <td></td> -->
                <td>&#8358;{{ net_sales.toLocaleString() }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>      
    </section>
  </div>
</template>

<style scoped>
* {
  box-sizing: border-box;
  color: #143E32;
}
ul {
  padding: 0;
}
.container {
  margin-top: 20px;
  border-top: #C4DBD5 1px solid;
  padding: 20px;
  padding-top: 50px;
}
 table {
  margin-top: 5px;
  border: #C4DBD5 1px solid;
  border-radius: 8px;
  border-spacing: 0;
 }
th {
  background: #FFF;
  text-align: left;
  border-bottom: 1px solid #C4DBD5;
  border-right: 1px solid #C4DBD5;
}
th:first-of-type {
  border-radius: 8px 0 0 0;
}
th:last-of-type {
  border-radius: 0 8px 0 0;
  border-right: none;
}
th, tr td {
  padding: 10px 7px;
}
td {
  border-bottom: 1px solid #C4DBD5;
  border-right: 1px solid #C4DBD5;
}
table tr:last-of-type td {
  border-bottom: none;
}
table tr td:last-of-type {
  border-right: none;
}
table tr:last-of-type td:first-of-type {
  border-radius: 0 0 0 8px;
}
table tr:last-of-type td:last-of-type {
  border-radius: 0 0 8px 0;
}
tr {
  background: #F5FCF4;
}
.light {
  opacity: 0.7;
  display: inline-block;
  margin-top: 5px;
}
.no_wrap {
  display: inline-block; width: 100px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
}
.flex {
  width: 15px;
}
</style>
