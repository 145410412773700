<script>
import { mapGetters } from "vuex";
// import { fetchMetrics } from "@/services/apiServices";

// import * as dayjs from "dayjs"

// const CardArray = [
//   {
//     // no function to immediately add order yet
//     img: require("@/assets/Images/basketIcon.svg"),
//     title: "Add a sale or order",
//     route: "/add_to_cart",
//   },
// ];

export default {
  components: {
  },
  data() {
    return {
      anonymous: {
        first_name: 'Anonymous',
        lifetime_orders: 0
      },
      ms_per_day: null,
      period: 30, // days
      utc1: null,
    };
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      customers: "getCustomers",
    }),
    activeCustomers() {
      let count = 0
      for (let i=0; i < this.customers.length; i++) {
        try {
          let purchase_year = parseInt(this.customers[i].last_purchase.substring(0, 4))
          let purchase_month = parseInt(this.customers[i].last_purchase.substring(5, 7)) - 1
          let purchase_day = parseInt(this.customers[i].last_purchase.substring(8, 10))

          let utc2 = Date.UTC(purchase_year, purchase_month, purchase_day)
          let difference = Math.floor((this.utc1 - utc2) / this.ms_per_day)

          difference < this.period ? count ++ : ""
        } catch { null }
      }
      return count
    },
    repeatCustomers() {
      // have made a purchase within time frame
      // have made a purchase at least once outside time frame
      // an easy way is to compare to their first purchase
      // possibly differentiate betwee repeat and return customers
      // 
      // There's also repeat customer within the given time frame
      let count = 0
      // let within = []
      // let before = []
      for (let i=0; i < this.customers.length; i++) {
        try {
          let purchase_year = parseInt(this.customers[i].last_purchase.substring(0, 4))
          let purchase_month = parseInt(this.customers[i].last_purchase.substring(5, 7)) - 1
          let purchase_day = parseInt(this.customers[i].last_purchase.substring(8, 10))

          let utc2 = Date.UTC(purchase_year, purchase_month, purchase_day)
          let difference = Math.floor((this.utc1 - utc2) / this.ms_per_day)

          if (difference < this.period) { //
            this.customers[i].lifetime_orders > 1 ? count ++ : ""
          }
        } catch { null }
      }
      return count
    },
    averageActiveSpend() {
      let total_spent = 0
      let count = 0
      for (let i=0; i < this.customers.length; i++) {
        try {
          let purchase_year = parseInt(this.customers[i].last_purchase.substring(0, 4))
          let purchase_month = parseInt(this.customers[i].last_purchase.substring(5, 7)) - 1
          let purchase_day = parseInt(this.customers[i].last_purchase.substring(8, 10))

          let utc2 = Date.UTC(purchase_year, purchase_month, purchase_day)
          let difference = Math.floor((this.utc1 - utc2) / this.ms_per_day)
          
          if (difference < this.period) {
            total_spent += this.customers[i].net_spend
            count ++
          }
        } catch { null }
      }

      return parseFloat(total_spent / count).toFixed()
    }, 
    customer_count() {
      // let plural
      let customer_count
      if (this.customers.length < 2) {
        if (this.anonymous.lifetime_orders > 0) {
          // anonymous sale has happened
          customer_count = 1
        } else {
           // no sale has happened
           customer_count = 0
        }
      } else {
        this.anonymous.lifetime_orders > 0
          ? customer_count = this.customers.length
          : customer_count = this.customers.length - 1
      }
      // customer_count > 1 ? (plural = "s") : (plural = "")

      return customer_count
    },
    newCustomers() {
      let count = 0
      for (let i=0; i < this.customers.length; i++) {
        try {
          let joined_year = parseInt(this.customers[i].created.substring(0, 4))
          let joined_month = parseInt(this.customers[i].created.substring(5, 7)) - 1
          let joined_day = parseInt(this.customers[i].created.substring(8, 10))

          let utc2 = Date.UTC(joined_year, joined_month, joined_day)
          let difference = Math.floor((this.utc1 - utc2) / this.ms_per_day)
          
          if (difference < this.period) {
            this.customers[i].lifetime_orders == 1 ? count ++ : ""
          }
        } catch { null }
      }
      return count
    },
    repeatPercent() {
      return (this.repeatCustomers / this.customer_count * 100).toFixed(1)
    },
    activePercent() {
      return (this.activeCustomers / this.customer_count * 100).toFixed(1)
    },
    newPercent() {
      return (this.newCustomers / this.customer_count * 100).toFixed(1)      
    }
  },
  mounted() {
    this.ms_per_day = 1000 * 60 * 60 * 24
    const today = new Date();
    this.this_day = today.getDay(); // position in week
        
    this.utc1 = Date.UTC(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    )
    
    this.anonymous = this.customers[this.customers.findIndex(customer => customer.first_name == "Anonymous")]
  }
};
</script>

<template>
  <div class="flex" style="margin-top: 20px; justify-content: space-between">
    <h2 style="font-size: 20px;">Customer stats.</h2>
    <div>
      <select v-model="period">
        <option value="7">7 days</option>
        <option value="30">30 days</option>
        <option value="90">90 days</option>
        <!-- <option value="">Lifetime</option> -->
      </select>
    </div>
  </div>
  <div class="flex mini_container border_r8">
    <div style="width: 15px;"></div>
    <div style="width: 100%;">
      <div class="flex border_r8" style="justify-content: space-between;">
        <div class="" style="padding: 10px; display: grid; place-items: center;">
          <div class="flex" style="align-items: center;">
            <img src="@/assets/Images/customerBlock.svg" alt="Icon" width="28px"/>
            <p style="margin-left: 10px;">Customers</p>
          </div>
          <p>{{ customer_count }}</p>
        </div>
        <div style="padding: 10px; display: grid; place-items: center;">
          <div class="flex" style="align-items: center;">
            <!-- <img src="@/assets/Images/customerBlock.svg" alt="Icon" width="28px"/> -->
            <p class="info">
              Average spend &#9432;
              <span class="text">Average spend of customers who made a purchase within the selected period.</span>
            </p>
          </div>
          <p>&#8358; {{ Number(averageActiveSpend).toLocaleString() }}</p>
        </div>
      </div>
      <div class="info_block flex" style="justify-content: space-between; margin-top: 10px">
        <div class="border_r8 pad_5">
          <p>New &#9432;<span class="text">Number, and percentage, of customers added within selected period</span></p>
          <p>{{ newCustomers }} / {{ newPercent }}%</p>
        </div>
        <div class="border_r8 pad_5">
          <p>Active &#9432;<span class="text">Number, and percentage, of customers that have made a purchase within the selected period.</span></p>
          <p>{{ activeCustomers }} / {{ activePercent }}%</p>
        </div>
        <div class="border_r8 pad_5">
          <p>Repeat &#9432;<span class="text">Number, and percentage, of customers that made a purchase within the selected period, and had also made a purchase before the period.</span></p>
          <p>{{ repeatCustomers }} / {{ repeatPercent }}%</p>
        </div>
      </div>
    </div>
    <div style="width: 15px;"></div>
  </div>
</template>

<style scoped>
  .mini_container {
    width: 100%;
    background: #F5FCF4;
    padding: 15px 0;
    margin-top: 5px
  }
  .info_block div {
    display: grid;
    width: 80px;
  }
  .info_block div p, p.info {
    position: relative;
    margin: auto;
  }
  .text {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;

    opacity: 0;
    transition: opacity 0.3s;    
  }
  .text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  .info_block div p:hover .text, p.info:hover .text {
    visibility: visible;
    opacity: 1;
  }
  .info {
    margin-left: 10px;
  }
</style>
