<script>
export default {
  name: "SalesAnalytics",
  props: [
    'analysis',
    'date_range',
  ],
  data() {
    return {
      order_count: 0,
      orders: [
        {
          unit: "Mon",
          orders: 0,
          products: 0,
          items: 0,
          percent: 0,
          amount: 0,
        },
      ],
      courier: [
        {
          name: "",
          count: 0,
          percent: 0,
          average_fee: 0,
          total_fees: 0,
        },
      ],
      sales_channels: [
        {
          channel: 0,
          order: 0,
          percent: 0,
          revenue: 0,
        },
      ],      
      payment_mode: [
        {
          mode: 0,
          count: 0,
          revenue: 0,
        },
      ],      
      pickup: [
        {
          mode: false, // find out if shiping or delivery
          orders: 0,
          items: 0,
          amount: 0,
        },
        {
          mode: true,
          orders: 0,
          items: 0,
          amount: 0,
        },
      ],      
    };
  },
  methods: {
    updateReport() {
      var total_orders_count = this.analysis.total_orders_count
      var net_sales = this.analysis.net_sales
      var shipping_costs = this.analysis.shipping_costs
      var total_collected = net_sales + shipping_costs

      // Pick-up v deliveries //
      this.pickup = this.analysis.pickup
      if (this.pickup.length < 2) {
        // Required to prevent dom error //
        if (this.pickup[0].mode == true) {
          this.pickup.push({
            mode: false,
            orders: 0,
            items: 0,
            amount: 0,
          })
        } else {
          this.pickup.push({
            mode: true,
            orders: 0,
            items: 0,
            amount: 0,
          })
        }
      }

      // Payment mode //
      let p_mode = this.analysis.payment_mode
  
      for (let i in p_mode) {
        p_mode[i].percent = Math.round(p_mode[i].revenue / total_collected * 100)
      }
      this.payment_mode = p_mode

      // Sales channels //
      let sales_mode = this.analysis.channel
      for (let i in sales_mode) {
        sales_mode[i].percent = Math.round(sales_mode[i].revenue / net_sales * 100)
      }
      this.sales_channels = sales_mode

      // Courier services //
      let service = this.analysis.shipping
      for (let i in service) {
        service[i].percent = Math.round(service[i].count / total_orders_count * 100)
        service[i].average_fee = Math.round(service[i].total_fees / service[i].count)
      }
      this.courier = service


      // var products = []
      // for (let i=0; i < this.breakdown.unique_products.length; i++) {
      //   let index = this.inventory.findIndex(product => product.id == this.breakdown.unique_products[i].id)
      //   try {var name = this.inventory[index].product_name}
      //   catch {name = "Unknown"}

      //   let percent = Math.round(this.breakdown.unique_products[i].amount / this.net_sales * 100)

      //   let product = {
      //     'product': name,
      //     'qty': this.breakdown.unique_products[i].qty,
      //     'percent': percent,
      //     'amount': this.breakdown.unique_products[i].amount,
      //   }
      //   products.push(product)
      // }
      // this.product_sales = products


      // // consider getting this other ways //
      // this.orders = this.breakdown.sales_count
      // let order_count = 0
      // for (let i=0; i < this.orders.length; i++) {
      //   order_count +=  this.orders[i].count
      // }
      // this.order_count = order_count

      // var max = 0
      // var widths = []
      // for (let i=0; i < this.orders.length; i++) {
      //   let width = this.orders[i].count / this.order_count * 100
      //   width > max ? max = width : ""
      //   widths.push(width)
      // }

      // var quotient = Math.floor(100 / max)

      // if (quotient > 1) {
      //   for (let i=0; i < this.orders.length; i++) {
      //     let id = this.orders[i].unit
      //     let unit = document.getElementById(id)
      //     unit.style.width = widths[i] * quotient + "%"
      //   }
      // } else {
      //   for (let i=0; i < this.orders.length; i++) {
      //     let id = this.orders[i].unit
      //     let unit = document.getElementById(id)
      //     console.log(unit)
      //     unit.style.width = widths[i] + "%"
      //   }
      // }


      // var patrons = this.breakdown.customers
      // for (let i in patrons) {
      //   patrons[i].percent = Math.round(patrons[i].total_spent / this.net_sales * 100)
      // }

      // patrons.sort((a, b) => (
      //   a.total_spent < b.total_spent ? 1 : b.total_spent < a.total_spent ? -1 : 0))

      // this.customers = patrons
    }
  },
  computed: {
    // ...mapGetters({

    // }),
  },
  mounted() {
    var max = 0
    var widths = []
    for (let i=0; i < this.orders.length; i++) {
      let width = this.orders[i].orders / this.order_count * 100
      width > max ? max = width : ""
      widths.push(width)
    }

    var quotient = Math.floor(100 / max)

    if (quotient > 1) {
      for (let i=0; i < this.orders.length; i++) {
        var id = this.orders[i].unit
        let unit = document.getElementById(id)
        unit.style.width = widths[i] * quotient + "%"
      }
    } else {
      for (let i=0; i < this.orders.length; i++) {
        let unit = document.getElementById(id)
        unit.style.width = widths[i] + "%"
      }
    }
	},
  watch: {
    analysis: {
      handler: function () {
        if (this.analysis.total_orders_count > 0) {
          this.updateReport()
        }
      },
    },
  },
};
</script>
<template>
  <div class="container">
    <section class="executive_summary">
      <h2 style="font-size: 28px">3. Sales analysis</h2>


      <section class="fulfilment_mode" style="margin-top: 50px">
        <h3 class="section_title">Order fulfilment</h3>
        <p class="prose">
          <!-- Something about pickup and deliveries. -->
        </p>
        <div style="margin-top: 30px;">
          <h4>Fulfillment modes from {{ date_range }}.</h4>
          <div class="">
            <div class="">
              <table style="width: 100%;">
                <tr>
                  <th>Mode</th>
                  <th>No of orders</th>
                  <th>No of items</th>
                  <th>Value</th>
                </tr>
                <tr v-for="fulfill, i in pickup" :key="i">
                  <td>
                    <span v-if="fulfill.mode == true">Pick-up</span>
                    <span v-else>Delivery</span>
                  </td>
                  <td>{{ fulfill.orders }}</td>
                  <td>{{ fulfill.items }}</td>
                  <td>&#8358;{{ fulfill.amount.toLocaleString() }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>   

        <!-- Graph not ready -->
        <div style="margin-top: 30px;">
          <h4 style="">Courier companies used for fulfilment.</h4>
          <div class="">
            <div class="">
              <table style="width: 100%;">
                <tr>
                  <th>Company</th>
                  <th>Order count</th>
                  <th>% of orders</th>
                  <th>Average fee</th>
                </tr>
                <tr v-for="company, i in courier" :key="i">
                    <td v-if="company.company != 'NA'">{{ company.company }}</td>
                    <td v-if="company.company != 'NA'">{{ company.count }}</td>
                    <td v-if="company.company != 'NA'">{{ company.percent }}%</td>
                    <td v-if="company.company != 'NA'">&#8358;{{ company.average_fee.toLocaleString() }}</td>
                </tr>
              </table>
            </div>
          </div>

          <!-- Graph not ready -->
          <h4 style="margin-top: 20px; display: none;">Courier companies visualised.</h4>
          <div class="mini_container" style="display: none;">
            <div class="flex"></div>
            <div class="sales_graph">
              <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <div>
                  <h4 style="display: inline-block; margin-top: 5px;">
                    {{ date_range }}
                  </h4>
                </div>
                <div class="select_box">
                  <span>Orders ^</span>
                </div>
              </div>
              <ul>
                <li v-for="order, i in this.orders" :key="i">
                  <span style="flex-basis: 40px; opacity: 0.8;">{{ order.unit }}</span>
                  <div style="flex-grow: 1;">
                    <div :id="order.unit" class="bar_graph">
                      <span style="color: #F5FCF4">#</span>
                      <!-- text too small -->
                      <!-- <span>&#8358; {{ order.orders }}</span> -->
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="flex"></div>
          </div>
        </div>   
      </section> 

      <section class="sales_channels" style="margin-top: 50px">
        <h3 class="section_title">Sales channels</h3>
        <p class="prose">
          <!-- Something about sales channels. -->
        </p>
        <div style="margin-top: 30px;">
          <h4>Break down of sales channels.</h4>
          <div class="">
            <div class="">
              <table style="width: 100%;">
                <tr>
                  <th>Channel</th>
                  <th>No of orders</th>
                  <th>% of sales</th>
                  <th>Revenue</th>
                </tr>
                <tr v-for="channel, i in this.sales_channels" :key="i">
                  <td>
                    <span v-if="channel.channel == 0">Shop</span>
                    <span v-else-if="channel.channel == 1">Instagram</span>
                    <span v-else-if="channel.channel == 2">Whatsapp</span>
                    <span v-else-if="channel.channel == 3">Website</span>
                    <span v-else-if="channel.channel == 4">Other</span>
                    <span v-else-if="channel.channel == 5">Facebook</span>
                    <span v-else-if="channel.channel == 6">X</span>
                  </td>
                  <td>{{ channel.orders }}</td>
                  <td>{{ channel.percent }}%</td>
                  <td>&#8358;{{ channel.revenue.toLocaleString() }}</td>
                </tr>
              </table>
            </div>
          </div>

          <h4 style="margin-top: 20px; display: none;">Sales channels visualised.</h4>
          <div class="mini_container" style="display: none;">
          <div class="sales_graph">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <div>
                <h4 style="display: inline-block; margin-top: 5px;">
                  {{ date_range }}
                </h4>
              </div>
              <div class="select_box">
                <span># of orders ^</span>
              </div>
            </div>

            <ul style="">
              <li
                v-for="channel, i in sales_channels" :key="i"
                style="position: relative; display: flex; flex-direction: column; height: 160px; width: 20px;
                align-items: center;"
              >
                <div :id="channel.channel" class="graph_bar"><!-- height of bar-->
                  <span class="graph_data"><!-- orders -->{{ channel.orders }}</span>
                  <span class="graph_colour"><!-- graph colour --></span>
                </div>
                <span style="position: absolute; bottom: -25px; width: 45px; text-align: center; opacity: 0.8;">{{ channel.channel }}</span>
              </li>
            </ul>
          </div>
        </div>   
        </div>   
      </section> 

      <!-- low priority. Code data incomplete -->
      <section class="payment_status" style="display: none; margin-top: 50px">
        <h3 class="section_title">Unpaid orders</h3>
        <p class="prose">
          Something about payment status.
        </p>
        <div style="margin-top: 30px;">
          <h4>Break down of payment status.</h4>
          <div class="">
            <div class="">
              <table style="width: 100%;">
                <tr>
                  <th>Company</th>
                  <th>% of orders</th>
                  <th>Order count</th>
                  <th>Average fee</th>
                </tr>
                <tr v-for="company, i in courier" :key="i">
                  <td>{{ company.company }}</td>
                  <td>{{ company.percent }}</td>
                  <td>{{ company.orders }}%</td>
                  <td>&#8358;{{ company.average_fee.toLocaleString() }}</td>
                </tr>
              </table>
            </div>
          </div>

          <h4 style="margin-top: 20px;">Unpaid orders services visualised.</h4>
          <div class="mini_container">
            <div class="flex"></div>
            <div class="sales_graph">
              <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <div>
                  <h4 style="display: inline-block; margin-top: 5px;">
                    Mon 17 - Sun 23 <br> July, 2024
                  </h4>
                </div>
                <div class="select_box">
                  <span>Orders ^</span>
                </div>
              </div>
              <ul>
                <li v-for="order, i in this.orders" :key="i">
                  <span style="flex-basis: 40px; opacity: 0.8;">{{ order.unit }}</span>
                  <div style="flex-grow: 1;">
                    <div :id="order.unit" class="bar_graph">
                      <span style="color: #F5FCF4">#</span>
                      <!-- text too small -->
                      <!-- <span>&#8358; {{ order.orders }}</span> -->
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="flex"></div>
          </div>
        </div>   
      </section> 

      <section class="payment_mode" style="margin-top: 50px">
        <h3 class="section_title">Payment mode</h3>
        <p class="prose">
          <!-- Something about payment mode. -->
        </p>
        <div style="margin-top: 30px;">
          <h4>Payment modes.</h4>
          <div class="">
            <div class="">
              <table style="width: 100%;">
                <tr>
                  <th>Channel</th>
                  <th>No of orders</th>
                  <th>% of collected</th>
                  <th>Amount</th>
                </tr>
                <tr v-for="channel, i in payment_mode" :key="i">
                  <td>
                    <span v-if="channel.mode == 0">Cash</span>
                    <span v-if="channel.mode == 1">Card</span>
                    <span v-if="channel.mode == 2">Transfer</span>
                    <span v-if="channel.mode == 3">Other</span>
                  </td>
                  <td>{{ channel.count }}</td>
                  <td>{{ channel.percent }}%</td>
                  <td>&#8358;{{ channel.revenue.toLocaleString() }}</td>
                </tr>
              </table>
            </div>
          </div>

          <h4 style="margin-top: 20px; display: none;">Courier services visualised.</h4>
          <div class="mini_container" style="display: none;">
            <div class="flex"></div>
            <div class="sales_graph">
              <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <div>
                  <h4 style="display: inline-block; margin-top: 5px;">
                   {{ date_range }}
                  </h4>
                </div>
                <div class="select_box">
                  <span>Orders ^</span>
                </div>
              </div>
              <ul>
                <li v-for="order, i in this.orders" :key="i">
                  <span style="flex-basis: 40px; opacity: 0.8;">{{ order.unit }}</span>
                  <div style="flex-grow: 1;">
                    <div :id="order.unit" class="bar_graph">
                      <span style="color: #F5FCF4">#</span>
                      <!-- text too small -->
                      <!-- <span>&#8358; {{ order.orders }}</span> -->
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="flex"></div>
          </div>
        </div>   
      </section> 
    </section>
  </div>
</template>

<style scoped>
* {
  color: #143E32;
  box-sizing: border-box;
}
ul {
  padding: 0;
}
.container {
  margin-top: 20px;
  border-top: #C4DBD5 1px solid;
  padding: 20px;
  padding-top: 50px;
}
.section_title {
  margin-top: 20px;
  text-decoration: underline;
}
.prose {
  margin-top: 10px;
  font-size: 18px;
  word-spacing: 2px;
  line-height: 24px;
}
 table {
  margin-top: 5px;
  border: #C4DBD5 1px solid;
  border-radius: 8px;
  border-spacing: 0;
 }
th {
  background: #FFF;
  text-align: left;
  border-bottom: 1px solid #C4DBD5;
  border-right: 1px solid #C4DBD5;
}
th:first-of-type {
  border-radius: 8px 0 0 0;
}
th:last-of-type {
  border-radius: 0 8px 0 0;
  border-right: none;
}
th, tr td {
  padding: 10px 7px;
}
td {
  border-bottom: 1px solid #C4DBD5;
  border-right: 1px solid #C4DBD5;
}
table tr:last-of-type td {
  border-bottom: none;
}
table tr td:last-of-type {
  border-right: none;
}
table tr:last-of-type td:first-of-type {
  border-radius: 0 0 0 8px;
}
table tr:last-of-type td:last-of-type {
  border-radius: 0 0 8px 0;
}
tr {
  background: #F5FCF4;
}
.light {
  opacity: 0.7;
  display: inline-block;
  margin-top: 5px;
}
.no_wrap {
  display: inline-block; width: 100px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
}
.mini_container > div:nth-of-type(2) {
  width: 100%;
}
.mini_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #F5FCF4;
  border: #C4DBD5 1px solid;
  border-radius: 8px;
  padding: 15px 0;
  margin-top: 5px;
}
.flex {
  width: 15px;
}
.sales_graph {
  padding: 0 20px;
}
.sales_graph ul {
  margin-top: 25px;
  display: flex; padding: 0 30px 20px; justify-content: space-between;
 }
 .graph_bar {
  display: flex; flex-direction: column; align-items: center; margin-top: auto; min-height: 2px; width: 100%;
 }
 .graph_data {
  display: block; margin-top: -20px; text-align: center;
 }
 .graph_colour {
  background: #008060; width: 100%; height: 100%; border-radius: 5px;
 }
.select_box {
  height: 34px;
  background: #FFF;
  border: #C4DBD5 1px solid;
  border-radius: 5px;
}
.select_box span {
  display: block;
  padding: 7px;
}
</style>
