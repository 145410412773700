<template>
  <div style="height: 50px;" v-if="display">
    <!-- pushes all pages down because of the header -->
  </div>
  <router-view />
  <AppNav v-if="display" /> <!-- v-if="logged_in" -->
</template>

<script>
import AppNav from '@/components/AppNav.vue'
import { mapGetters } from 'vuex';

export default {
  components: {
    AppNav
  },
  computed: {
		...mapGetters({
			display: 'getAppNavDisplay',
			loggedIn: 'getLoggedIn',
		})
  },
  mounted() {

  }
}
</script>

<style>
* {
  /* box-sizing: border-box; */
}
html {
  scroll-behavior: smooth;
}
body{
  background: #F0F5EF;
}
@font-face {
  font-family: 'Aeonik';
  src: url('./assets/Fonts/AeonikTRIAL-Regular.otf'); 
}
#app {
  font-family: 'Aeonik', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}
nav {
  padding: 30px;
}
nav a {
  font-weight: bold;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #42b983;
}
ul {
  list-style-type: none;
}
  /* UNIVERSAL STYLING */
.pad_5 {
  padding: 5px;
}
.pad_10 {
  padding: 10px;
}
.pad_15 {
  padding: 15px;
}
.flex {
  display: flex;
}
.flex_column {
  display: flex;
  flex-direction: column;
}
.border_r8 {
  border: #C4DBD5 1px solid;
  border-radius: 8px;
}

</style>
