<script>
import cancelDark from "@/assets/Images/cancelDark.svg";

import MarkComplete from "@/components/Order/MarkComplete.vue";

export default {
  name: 'MenuOptionsLarge',
  components: {
    MarkComplete,
  },
  props: {
    option: {
      type: String,
      required: true,
      default: null,
    },
    order: [],
    // properties: [],
  },
  data() {
    return {
      cancelDark,
      displayProcess: true,
    };
  },
  methods: {
    closePopup() {
      this.$emit("cancel")
      this.$emit("closeMenu")
    },
    process() {
      switch (this.option) {
        case 1: // delete order
          // this.activeOrder = order;
          // this.showOverlay = true;
          // this.overlayTitle = "Delete order";
          // this.overlayMessage =
          //   "This will permanently delete this order and all associated data";
          // this.overlaySubMessage = "";
          break;
        case 2: // update paid amount
          null
          break;
        case 3: // cancel
          null
          break;
        case 4:
          null
          break;
        default:
          null
      }
    }
  },
  watch: {
    option() {
      switch (this.option) {
        case 'markComplete':
          this.displayProcess = false
          break;
        case 2: // update paid amount
          null
          break;
        case 3: // cancel
          null
          break;
        case 4:
          null
          break;
        default:
          null
      }
    }
  }
};
</script>
<template>
  <div class="modal" v-if="option != null">
    <div class="container_holder">
      <div class="cancel_container">
        <div class="cancel">
          <img :src="cancelDark" alt="cancel" @click="closePopup" />
        </div>
      </div>
      <div class="container">
        <!-- Title -->
        <div class="title">
          <h1 class="h1">Mark fulfilled</h1>
        </div>
        <div style="height: 50px;"></div>

        <!-- Unique content -->
        <MarkComplete
          v-if="option=='markComplete'"
          :order=order
        />
        <!-- End, unique content -->

        <div v-if="displayProcess" class="action">
          <div style="width: 15px"></div>
          <button @click="process()">Save</button>
          <div style="width: 15px"></div>
        </div>
        <div style="height: 70px;"></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  backdrop-filter: blur(3px);
  z-index: 1001;
}
.cancel_container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 16px;
}
.cancel {
  background-color: white;
  padding: 6px;
  padding-bottom: 4px;
  border-radius: 8px;
  margin-right: 12px;
}
.container {
  background: #f0f5ef;
  border-radius: 16px 16px 0 0;
  border: none;
  width: 100%;
  height: 95%;
  overflow-y: scroll;
}
.container_holder {
  height: 85%;
}
.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 24px 16px;
  border: 1px solid #c4dbd5;
}
.title {
  position: fixed;
  width: 100%;
  background: #F0F5EF;
  border-radius:
  16px 16px 0 0;
  z-index: 50;
}
.subject {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
  align-items: center;
}
.h1 {
  color: #002e22;
  font-size: 20px;
  font-weight: bold;
  padding: 20px 16px;
}
h2 {
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 600;
  color: #143e32;
}
.action {
  display: flex;
  position: fixed; bottom: 0;
  left: 0;
  width: 100%;
  background: #F0F5EF;
}
span {
  background: #f9f3db;
  color: #a75a00;
  padding: 8px;
  font-size: 12px;
  margin-right: 5px;
  border-radius: 24px;
}
p {
  color: #355f53;
}
button {
  background-color: #008060;
  color: white;
  border: none;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 12px;
}
.main-text {
  display: flex;
  padding: 16px;
  flex-direction: column;
}
.cancel img {
  width: 12px;
  height: 12px;
  background: #90a59d;
  padding: 9px;
  border-radius: 50px;
}
.order_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
</style>
