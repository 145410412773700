<script>
import CustomHomeHeader from "@/components/CustomHomeHeader.vue"
import Hero from "@/assets/Images/OrderHero.svg"

import { fetchSalesReport } from "@/services/apiServices"
import { SAVE_SALES_REPORT } from "@/store/mutationTypes"

import SalesOverview from "@/components/Reports/SalesOverview"
import SalesBreakdown from "@/components/Reports/SalesBreakdown"
import SalesAnalytics from "@/components/Reports/SalesAnalytics"

export default {
  name: "SalesReport",
  components: {
    CustomHomeHeader,
    SalesOverview,
    SalesBreakdown,
    SalesAnalytics,
  },
  data() {
    return {
      Hero,
      date_range: "",
      analysis: [],
      report: [],
      breakdown: [],
      net_sales: 0,
      report_period: null,
      current_month: null,
      stale_products: [],
      no_sales: [{product_name: ''}],
      weekly_sales: [],
      // displayReport: false,
      // report_load_counter: 0,
    };
  },
  methods: {
    getReportData() {
      switch (this.report_period) {
        case 2:
          this.date_range = this.period[this.report_period].month + " 1 - 28"
          break;
        case 4:
        case 6:
        case 9:
        case 11:
          this.date_range = this.period[this.report_period].month + " 1 - 30"
          break;
        case 0:
          null
          break;
        default:
          this.date_range = this.period[this.report_period].month + " 1 - 31"
      }

      fetchSalesReport(this.report_period)
        .then(res => {
          this.report = res.data.sales_overview.sales_overview
          this.breakdown = res.data.sales_overview.sales_breakdown
          this.net_sales = res.data.sales_overview.sales_overview.sales
          this.analysis = res.data.sales_overview.sales_analysis
          this.$store.commit(SAVE_SALES_REPORT, res.data)
          this.no_sales = res.data.sales_overview.sales_breakdown.no_sales
          this.weekly_sales = res.data.sales_overview.sales_overview.breakdown
          // this.report.sales > 0
        })
    },
  },
  computed: {
    period() {
      let periods = []
      let months = [
        'Year-to-date', 'January', 'February', 'March', 'April', 'May', 'June', 
        'July', 'August', 'September', 'October', 'November', 'December'
      ]
      for (let i=0; i < this.current_month + 1; i++) {
        periods.push({'month': months[i]})
      }
      return periods
    }
  },
  beforeMount() {
    const today = new Date()
    this.report_period = today.getMonth()
    this.current_month = today.getMonth()
    this.getReportData()
  }
};
</script>
<template>
  <CustomHomeHeader text="Sales report" link="Reports Index" />

  <div style="margin-top: 10px; padding: 20px;">
    <select v-model="report_period" @change="getReportData()" style="position: absolute; right: 0; margin-right: 20px;">
      <option disabled value="">Select report period</option>
      <option v-for="period, i in period" :key="i" :value="i">{{ period.month }}</option>
    </select>
  </div>

  <!-- <div  v-if="displayReport"> -->
  <div>
    <!-- Add notes for which reports include shipping and which ones don't -->
    <!-- Clairfy % of sales (currency value or order count) -->
    <!-- Test: All the revene from all the breakdowns should be the same -->
    <SalesOverview :date_range="date_range" :sales_report="report" />
    <SalesBreakdown
      :date_range="date_range" 
      :breakdown="breakdown" 
      :net_sales="net_sales" 
      :no_sales="no_sales"
      :weekly_sales="weekly_sales"
    />
    <SalesAnalytics :date_range="date_range" :analysis="analysis"/>
  </div>
  <!-- <div v-else>
    You do not have any sales from {{ date_range }}
  </div> -->
  <div style="height: 100px;"></div>
</template>

<style scoped>
</style>