<script>
import returnHome from "@/assets/Images/returnHome.svg";
import cancel from '@/assets/Images/cancelFaint.svg'
import cancelDark from '@/assets/Images/cancelDark.svg'
import coloredPlus from '@/assets/Images/coloredPlus.svg'
import CustomHeader from '../CustomHeader'

const Cards = [
    {
        name: 'Product 1',
        image: require('@/assets/Images/imgAvatar.svg')
    },
    {
        name: 'Product 1',
        image: require('@/assets/Images/imgAvatar.svg')
    },
    {
        name: 'Product 1',
        image: require('@/assets/Images/imgAvatar.svg')
    },
    {
        name: 'Product 1',
        image: require('@/assets/Images/imgAvatar.svg')
    }
]
export default {
    data() {
        return {
            returnHome,
            cancel,
            Cards,
            cancelDark,
            coloredPlus
        }
    },
    components: {
        CustomHeader
    }
}

</script>
<template>
<CustomHeader text="Add Product Image"/>
    <div class="notice">
        <div class="notice_header">
            <span>Note</span>
            <button class="cancel"><img :src="cancel" alt=""></button>
        </div>
        <p class="first">Add as many products as you like or import them for convenience, here is a <RouterLink to="/">
                Quick tip</RouterLink>
        </p> <br />
        <p>Choose one image per product to start; you can add more images later.</p>
    </div>
    <div>
        <div class="card" v-for="i in Cards" :key="i">
            <div class="first_sec">
                <img :src="i.image" alt="avatar">
                <div>
                    <h1>{{ i.name }}</h1>
                </div>
            </div>
            <button class="cancel">
                <img :src="cancelDark" alt="cancel icon" @click="$router.back()">
            </button>
        </div>
        <div class="add">
            <img :src="coloredPlus" alt="coloredPlus">
            <RouterLink to="/uploadImg">Add Product</RouterLink>
        </div>
    </div>
<div class="btn">
<button>Proceed to add details</button>
</div>
</template>



<style scoped>
.header_main {
    padding: 10px 0;
    border-bottom: 1px solid rgba(53, 95, 83, 0.5);
    display: flex;
    align-items: center;
}

.arr {
    padding: 4px;
    background-color: rgba(53, 95, 83, 0.5);
    border-radius: 4px;
    margin: 0 8px;
}

.notice {
    display: flex;
    flex-direction: column;
    color: rgba(53, 95, 83, 1);
    padding: 12px;

}

.notice div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notice div>span {
    padding: 4px 8px;
    border-radius: 24px;
    font-size: 14px;
    color: red;
    background-color: rgba(206, 60, 85, 0.1);
}

.notice>p {
    font-size: 16px;
    margin-top: 10px;

}

.notice_header {
    display: flex;
    justify-content: space-between;
}

.cancel {
    padding: 8.42px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 7.15px;
    color: #92AAA3;
    background-color: rgba(146, 170, 163, 0.4);
    height: 24px;
    border-radius: 50%;
    border: none;
    margin-right: 12px;
}

.cancel>img {
    width: 7.15px;
    height: 7.16px;
}

.notice {
    background: white;
    border-radius: 8px;
    margin: 0;
    margin-bottom: 8px;
    padding: 12px;
    margin: 16px;
    color: #355F53;
}

.card {
    margin-bottom: 8px;
    padding: 12px 8px;
    background-color: rgb(245, 252, 244);
    border: 2px solid rgba(196, 219, 213, 1);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 8px 16px;
    border-radius: 8px;
}

.first_sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

h1 {
    font-size: 14px;
    font-weight: 500;
    color: rgba(20, 62, 50, 1);
    margin-left: 8px;
}

a {
    margin-left: 4px;
    color: rgba(0, 128, 96, 1);
}

.add {
    display: flex;
    align-items: center;
    margin-left: 16px;
}
.btn{
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
bottom: 0;

}
.btn > button{
    display: flex;
    justify-content: center;
    background-color: rgba(0, 128, 96, 1);
    color: white;
    border-radius: 4px;
    width: 90%;
    padding: 12px;
    margin-bottom: 21px;
    border: none;
    font-size: 16px;
    font-weight: 500;
}
</style>