<script>
import noPayment from "@/assets/Images/noPayment.svg";
import cancelDark from "@/assets/Images/cancelDark.svg";
import plusWhite from "@/assets/Images/plusWhite.svg";
import drpdown from '@/assets/Images/select.svg'
export default {
  props: {
    isAddPayment: {
      type: Boolean,
      required: true,
        },
      
  },
  data() {
    return {
      noPayment,
      cancelDark,
        plusWhite,
      drpdown
    };
  },
  methods: {
    closePopup() {
      this.$emit("cancel");
    },
  },
};
</script>
<template>
  <div class="modal" v-if="isAddPayment">
    <div class="container_holder">
      <div class="cancel_container">
        <div class="cancel">
          <img :src="cancelDark" alt="cancel" @click="closePopup" />
        </div>
      </div>
      <div class="container">
        <h1>Add or Update Payment</h1>
        <div class="content">
          <div>
            <p>Amount paid</p>
            <input :style="{width: '93%'}" type="text" placeholder="NGN 5,000" />
          </div>
          <div>
            <p>Payment mode</p>
              <select class="custom">
        <option value="1">Cash</option>
        <option value="2">Health & Beauty</option>
        <option value="3">Transfer</option>
        <option value="4">POS</option>
        <option value="6">Bank Transfer</option>
        <option value="5">Gift</option>
      </select>
          </div>
             <button :style="{marginTop: '15px', padding: '12px', border: 'none', borderRadius: '4px' }">Save update</button>
        </div>
     
      </div>
    </div>
  </div>
</template>



<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  z-index: 1002;
}
.cancel_container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 16px;
}
.cancel {
  background-color: white;
  padding: 8px;
  border-radius: 8px;
  margin-right: 12px;
}
.container {
  background: #F0F5EF;
  border-radius: 5px;
  width: 100%;
}
.container_holder {
    width: 95%;
}
input,
select {
  border: 1px #c4dbd5 solid;
  border-radius: 6px;
  width: 100%;
  padding: 12px;
  margin-bottom: 8px;
  outline: none;
  background-color: #F5FCF4;
}

input::placeholder {
  color: #92aaa3;
}

input:focus,
.custom:focus {
  border: #008060 1px solid;
}

.custom {
  width: 100%;
  appearance: none;
  background-image: url('@/assets/Images/select.svg');
  background-size: 25px;
  background-position: right 8px center;
  background-repeat: no-repeat;
  padding-right: 25px;
}

.content {
  display: flex;
  width: auto;
  flex-direction: column;
  padding: 24px 16px;
  border: 1px solid #c4dbd5;
}
h1 {
  color: #002e22;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 16px;
}
h2 {
  font-size: 32px;
  font-weight: 600;
  color: #143e32;
}
h3 {
  font-weight: 500;
  font-size: 18px;
  color: #002e22;
}
span {
  background: #008060;
  color: white;
  font-size: 12px;
  margin-right: 5px;
}
p {
  color: #355f53;
    margin: 10px 0;
}
button {
  background-color: #008060;
  color: white;
}
button img {
  margin-right: 8px;
}
.main-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 250px;
}
.cancel img {
  width: 12px;
  height: 12px;
  background: #90a59d;
  padding: 12px;
  border-radius: 50px;
}
select{
    width: 100%;
    padding: 10px;
}
</style>
