<script>
import CustomHomeHeader from "@/components/CustomHomeHeader.vue";
import dashboardHero from "@/assets/Images/dashboardHero.svg";
import plusWhite from "@/assets/Images/plusWhite.svg";
import CustomerStats from "@/components/Dashboard/CustomerStats.vue";
import BestSeller from "@/components/Dashboard/BestSeller.vue";
// import BusinessOverview from '@/components/Dashboard/BusinessOverview.vue'
// import ExpensesChart from '@/components/Dashboard/ExpensesChart.vue'
// import GraphComponent from '../components/GraphComponent.vue'
import { mapGetters } from "vuex";
import { fetchMetrics } from "@/services/apiServices";
import * as mutationTypes from "@/store/mutationTypes";

import * as dayjs from "dayjs"

const CardArray = [
  {
    img: require("@/assets/Images/shop.svg"),
    title: "Add your first product",
    route: "/product_form",
  },
  {
    // no function to immediately add order yet
    img: require("@/assets/Images/basketIcon.svg"),
    title: "Add a sale or order",
    route: "/add_to_cart",
  },
  {
    img: require("@/assets/Images/basketIcon.svg"),
    title: "Add a customer",
    route: "/customer_form/customer/",
  },
  // {
  // 	// no expense yet
  // 	img:require('@/assets/Images/script.svg'),
  // 	title:'Record expense',
  // 	route:'/expense'
  // }
];

export default {
  components: {
    CustomHomeHeader,
    CustomerStats,
    // BusinessOverview,
    BestSeller,
    // ExpensesChart,
    // GraphComponent
  },
  data() {
    return {
      best_sellers: [],
      CardArray,
      current_month: null,
      dashboardHero,
      sales_data_type: 0, // net_sales, order_count, etc
      metrics: [
          {
          title: 'Total Sales',
          currency: 1,
          data: 0,
          up: 1,
          delta: 0,
          icon: 'Sale',
          percent: 0,
          period: '-',
          }
        ],
      period: 2, // [1,2,3,4]:[today, week, month, year]
      plusWhite,
      report: {
        sales: 0,
        breakdown: [
            {'unit': "1-7", 'amount': 0},
            {'unit': "8-14", 'amount': 0},
            {'unit': "15-21", 'amount': 0},
            {'unit': "22-28", 'amount': 0},
            {'unit': "29-31", 'amount': 0},
        ],
      }
    };
  },
  methods: {
    updateMetrics() {
      fetchMetrics(this.period).then((res) => {
        this.metrics = res.data.stats
        this.report = res.data.sales_overview.sales_overview

        let till = []
        this.$store.commit(mutationTypes.SAVE_METRICS, res.data)
        res.data.best_sellers.map((item) => {
          let obj = this.inventory.find(
            (product) => product.id == item.productid
          );

          let new_obj = {};
          Object.assign(new_obj, obj);

          let percent = (item.count / res.data.total_items_in_period) * 100;
          new_obj.count = item.count;
          new_obj.percent = Math.round(percent);

          till.push(new_obj);
        });
        this.best_sellers = till;


        if ( this.report.sales > 0 ) {
          var max = 0
          var widths = []
          for (let i=0; i < this.report.breakdown.length; i++) {
            let width = this.report.breakdown[i].amount / this.report.sales * 100
            width > max ? max = width : ""
            widths.push(width)
          }

          // Makes the data fill the graph area as best as possible //
          var ratio = (100 / max).toFixed(3)
          if (ratio > 1) {
            for (let i=0; i < this.report.breakdown.length; i++) {
              let id = this.report.breakdown[i].unit

              let unit = document.getElementById(id)
              unit.style.height = Math.floor(widths[i]) * ratio + "%"
            }
          } else {
            for (let i=0; i < this.report.breakdown.length; i++) {
              let id = this.report.breakdown[i].unit
              let unit = document.getElementById(id)
              unit.style.height = widths[i] + "%"
            }
          }
        } 
      });
    }
  },
  computed: {
    ...mapGetters({
      inventory: "getInventory",
    }),
    timeOfDay() {
      let hrs = dayjs().get("hours");
      return hrs < 12
        ? "morning"
        : hrs >= 12 && hrs < 6
        ? "afternoon"
        : "evening";
    },
    this_month() {
      let months = [
        'Year-to-date', 'January', 'February', 'March', 'April', 'May', 'June', 
        'July', 'August', 'September', 'October', 'November', 'December'
      ]
      return months[this.current_month]
    },
    last_month() {
      let months = [
        'Year-to-date', 'January', 'February', 'March', 'April', 'May', 'June', 
        'July', 'August', 'September', 'October', 'November', 'December'
      ]
      return months[this.current_month - 1]
    },
    date_range() {
      switch (this.current_month) {
        case 2:
          return this.this_month + " 1 - 28"
        case 4:
        case 6:
        case 9:
        case 11:
          return this.this_month + " 1 - 30"
        case 0:
          return null
        default:
          return this.this_month + " 1 - 31"
      }
    },
    stats() {
      return [
        // {
        //   stat: "Revenue",
        //   value: "N345,000",
        // },
        // {
        //   stat: "Revenue",
        //   value: "N345,000",
        // },


        // {
        //   stat: "Orders",
        //   value: this.metrics.stats[1].data + " total"
        // },
        // {
        //   stat: "Items sold",
        //   value: this.metrics.items_in_period > 0 ? this.metrics.items_in_period + " total" : 0
        // },
        // {
        //   stat: "Open", // because some are pick up
        //   value: this.metrics.complete > 0 ? this.metrics.stats[1].data - this.metrics.complete + " orders" : 0
        // },
        // {
        //   stat: "-", // Outstanding deliveries
        //   value: "-",
        // }, 


        // {
        //   stat: "Dispatches", // Outstanding deliveries
        //   value: "4 outstanding",
        // }, 
        // {
        //   stat: "Unresolved",
        //   value: "1 refund",
        // }, {
        //   stat: "Vendor owed",
        //   value: "1",
        // }
        // {
        //   stat: "Ship today",
        //   value: "3 orders",
        // },
        // {
        //   stat: "Receivables",
        //   value: "1 customer", // also, monetary value and margin
        // },
        // {
        //   stat: "Sales",
        //   value: "7 today",
        // },
        // {
        //   stat: "Revenue",
        //   value: "N345,000",
        // },  {
        //   stat: "Unresolved",
        //   value: "1 refund",
        // }, {
        //   stat: "Vendor owed",
        //   value: "1",
        // }
      ];
    },
  },
  mounted() {
    const today = new Date()
    this.current_month = today.getMonth() + 1
    this.updateMetrics() // do not put in beforeMount. Causes infinite loop. //
  },
  beforeMount() {
  },
};
</script>

<template>
  <CustomHomeHeader text="Dashboard" />
  <div v-if="false" class="container">
    <!-- <div class="container">
			<img :src='dashboardHero' alt="cartoon of a lady typing ">
			<p>No data yet</p>
		</div> -->
    <div class="second_container">
      <h1>What would you like to start with?</h1>
      <div class="group">
        <div v-for="i in CardArray" :key="i">
          <RouterLink :to="i.route" class="inner">
            <img :src="i.img" alt="{{ i.name }} Icon" />
            <span> {{ i.title }} </span>
          </RouterLink>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <!-- Dashboard here -->
    <div style="padding: 20px;">
      <h3>Good {{ timeOfDay }}</h3>
      <p style="margin-top: 5px;" class="">Here’s how is your bussiness is doing this {{ this_month }}.</p>
    </div>

    <div class="container">
      <div style="background: #f5fcf4; border: #c4dbd5 1px solid; border-radius: 8px; display: flex; padding: 10px; margin-top: 20px;">
        <div>
          <img src="@/assets/Images/shop.svg" alt="Icon" />
        </div>
        <div style="margin-left: 20px; ">
          <p style="margin-bottom: 5px; font-size: 16px;">{{ metrics[0].title }}</p>
          <div>
            <h3 class="h3" style="display: inline-block;">
              <span v-if="metrics[0].currency == 1" style="margin: 0;"> &#8358;</span>
              {{ metrics[0].data.toLocaleString() }}
            </h3>
          </div>
          <p style="font-size: 16px; margin-top: 3px;">
            <span style="color: #3CB29A;">
              <img v-if="metrics[0].up == 1" src="@/assets/Images/arrow_up_green.svg" alt="Icon" />
              <img v-if="metrics[0].up == 0" src="@/assets/Images/arrow_down_red.svg" alt="Icon" />

              {{ metrics[0].percent }}%
            </span> compared to {{ last_month }}
          </p>
        </div>
      </div>

      <div class="mini_container border_r8 flex">
        <div class="flex"></div>
        <div class="sales_graph">
          <div style="">
            <div style="">
              <h4 style="font-size: 18px; text-align: center; margin: 0 20px 40px;">
                Weekly sales for {{ date_range }}
              </h4>
            </div>
            <!-- <div style="height: 77px;">
              <div class="header_mini" style="display: flex; flex-direction: column; position: absolute; right: 0; margin:0 20px 0 0;">
                <p style="font-size: 17px; margin-bottom: 5px;">Select data</p>
                <select class="custom" v-model="sales_data_type" @change="updateMetrics">
                  <option  value="0">Total sales</option>
                  <option value="1">No of orders</option>
                </select>
              </div>
            </div> -->
          </div>
          <ul style="display: flex; height: 160px; justify-content: space-evenly; margin: 0 50px 20px; justify-content: space-between; padding: 0;">

            <li v-for="period, i in report.breakdown" :key="i"
              style="position: relative; display: inline-block; width: 20px;"
            >
              <div style="position: relative; height: 100%;">
                <div :id="period.unit" class="bar_graph">
                  <span style="color: #F5FCF4;"><!-- bar of graph --></span>
                  <span class="data">
                    <span v-if="period.amount != null" >&#8358;&nbsp;{{ period.amount.toLocaleString() }}</span>
                  </span>
                </div>
              </div>
              <span style="display: inline-block; width: 40px; font-size: 14px; opacity: 0.8; margin-left: -7px;">{{ period.unit }}</span>
            </li>
            
          </ul>
        </div>
        <div class="flex"></div>
      </div>

      <CustomerStats />

      <!-- <SummaryBlocks :stats="stats" /> -->
      <div>
        <div class="selling_header" style="margin-top: 40px">
          <h2 style="font-size: 20px;">Best sellers</h2>
          <a href="">View All</a>
        </div>
        <BestSeller :best="best_sellers" />
      </div>
    </div>
  </div>
</template>

<style scoped>
  .container {
    /* display: flex; */
    justify-content: center;
    flex-direction: column;
    margin: 0 16px;
  }
  .second_container {
    margin: 70px 16px;
    margin-bottom: 0;
  }
  .card {
    display: flex;
  }
  p {
    font-weight: 500;
    font-size: 18px;
  }
  h1 {
    font-weight: 500;
    font-size: 16px;
  }
  button {
    background-color: #008060;
    border-radius: 4px;
    border: none;
    color: #fff;
    padding: 8px 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  button > img {
    margin-right: 4px;
  }
  .group {
    display: flex;
    flex-direction: column;
    background-color: #f5fcf4;
    border: #c4dbd5 1px solid;
    border-radius: 8px;
    margin-top: 21px;
    width: 100%;
  }
  .group > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 14px;
    border-bottom: #c4dbd5 1px solid;
  }
  .inner {
    display: flex;
    align-items: center;
    color: #355f53;
  }
  a {
    text-decoration: none;
  }
  .custom {
    width: 114px;
    appearance: none;
    background-image: url("../assets/Images/select.svg");
    background-size: 25px;
    background-position: right 8px center;
    background-repeat: no-repeat;
    padding-right: 25px;
    background-color: white;
    color: #92aaa1;
  }
  select {
    border: 1px #c4dbd5 solid;
    border-radius: 6px;
    width: 100%;
    padding: 12px;
    margin-bottom: 8px;
    outline: none;
  }
  .header_mini {
    display: flex;
    justify-content: end;
    margin: 16px 0;
  }
  a {
    color: #008060;
  }
  .selling_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h1 {
    font-size: 16px;
    font-weight: 500;
    color: #002e22;
    padding: 16px 0;
  }
  .mini_container {
    width: 100%;
    background: #F5FCF4;
    padding: 15px 0;
    margin-top: 20px
  }
  .mini_container > div:nth-of-type(2) {
    width: 100%;
  }
  .mini_container span {
    margin: 0;
  }
  .sales_graph ul {
    margin-top: 25px; 
  }
  .sales_graph ul li { /* didn't seem to have any effect */
    /* display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 10px;  */
  }
  .bar_graph {
    position: absolute;
    bottom: 0;
    width: 25px;
    min-height: 5px;
    background: #008060;
    border-radius: 5px;
    /* color: #FFF; */

  }
  .bar_graph span {
    /* display: inline-block; */
    /* width: 100%; */
    /* text-align: right; */
    opacity: 0.9;
  }
  .data{
    display: inline; 
    width: 110px; 
    font-size: 14px; 
    text-align: center; 
    position: relative; 
    top: -20px; 
    left: -20px;
  }
</style>
