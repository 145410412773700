<script>
import returnHome from "@/assets/Images/returnHome.svg";
import searchIcon from "@/assets/Images/searchIcon.svg";
import newIcon from "@/assets/Images/plusWhite.svg";
import CustomHeader from "@/components/CustomHeader.vue";
import SearchBar from "@/components/SearchBar.vue";

import { SET_PRODUCT_TO_EDIT } from "@/store/mutationTypes";
import { mapGetters } from "vuex";
import { DISPLAY_APP_NAV, SAVE_CART_MAP } from "@/store/mutationTypes";

export default {
  name: "AddToCart",
  components: {
    CustomHeader,
    SearchBar,
  },
  data() {
    return {
      returnHome,
      newIcon,
      searchIcon,
      selectedProducts: [],
      baseUrl: "",
      input: "",
      origin: "orders",
    };
  },
  methods: {
    addProduct() {
      this.$router.push("/product_form/order");
      // add new product
      // come back to the same screen (display product)
      // also mark product as selected (add it's ID to cart)
    },
    goToCart() {
      if (this.selectedProducts.length > 0) {
        this.$store.commit(SAVE_CART_MAP, this.selectedProducts);
        this.$router.push("/cart");
      } else {
        this.showPopUp = true;
        console.log("it is visible");
      }
    },
    toggleProductSelection(id) {
      let index = this.selectedProducts.indexOf(id);
      index == -1
        ? this.selectedProducts.push(id)
        : this.selectedProducts.splice(index, 1);


      this.$store.commit(SET_PRODUCT_TO_EDIT, {});

    },
    isSelected(i) {
      return this.selectedProducts.includes(i);
    },
  },

  computed: {
    ...mapGetters({
      inventory: "getInventory",
      existingCartMap: "getCartMap",
    }),
    filterAvailable() {
      // modify to apply only if strict stock count
      return this.inventory.filter((product) => product.total_stock > 0);
    },
    filteredList() {
      return this.filterAvailable.filter((product) =>
        product.product_name.toLowerCase().includes(this.input.toLowerCase())
      );
    },
    selectedProductCount() {
      return this.selectedProducts.length;
    },
  },
  mounted() {
    this.$store.commit(DISPLAY_APP_NAV, false);
    this.baseUrl = window.location.origin;
    if (this.existingCartMap.length > 0) {
      this.selectedProducts = this.existingCartMap;
    }
  },
  unmounted() {
    this.$store.commit(DISPLAY_APP_NAV, true);
  },
};
</script>

<template>
  <CustomHeader text="Select products to order" />
  <div style="height: 40px;">
    <!-- pushes CustomHeader down -->
  </div>
  <form>
    <div class="search-container">
      <SearchBar v-model="input" placeholder="Search for product..." />

      <button style="margin-left: 10px" @click="addProduct()">
        Add New Product
      </button>
    </div>
    <div class="selected_products">
      Selected products: {{ selectedProductCount }}
    </div>
    <div class="products">
      <div v-for="(product, i) in filteredList" :key="i" class="product_holder">
        <label
          for=""
          
        >
          <div
            v-if="product.product_image"
            @click="toggleProductSelection(product.id)"
            :class="{ 'selected-image': isSelected(product.id) }"
          >
            <img
              v-if="baseUrl == 'http://127.0.0.1:8080'"
              :src="'http://127.0.0.1:8000' + product.product_image"
              alt="img"
              class="cart_imgs"
            />
            <img
              v-else
              :src="product.product_image"
              alt="img"
              class="cart_imgs"
            />
          </div>
          <div v-else
            @click="toggleProductSelection(product.id)"
            :class="{ 'selected-image': isSelected(product.id) }"
          >
            <img src="@/assets/Images/default_product_image.png" 
              alt="img" 
              class="cart_imgs"
            />
          </div>

          <div class="">
            <p class="details">{{ product.product_name }}</p>
          </div>
        </label>
      </div>
      <div class="product_holder"></div>
      <div class="product_holder"></div>
    </div>
    <PopUpScreen
      :isVisible="showPopUp"
      title="No product selected"
      header="Oh no!"
      message="Seems like you forgot to select a product"
      @close="showPopUp = false"
    />
  </form>

  <div class="btn">
    <button @click="goToCart">Go to cart</button>
  </div>
  <div style="display: block; height: 100px"><!--props page up--></div>
</template>

<style scoped>
.arr {
  padding: 4px;
  background-color: rgba(53, 95, 83, 0.5);
  border-radius: 4px;
  margin: 0 8px;
}

form {
  display: flex;
  /* width: 100%; */
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
  /* margin-bottom: 16px; */
  margin: 16px;
}

.search-container {
  width: 100%;
  display: flex;
  margin: 0 16px;
}

.products {
  display: flex;
  flex-wrap: wrap;
}
.products > div {
  /* let the margin be zero or as small as possible */
  /* margin: 5px; */
}
.selected_products {
  margin: 16px 0;
  width: 100%;
}
.product_holder {
  flex: 1 0 30%;
  margin: 5px;
  position: relative;
}
.cart_imgs {
  width: 100%;
  object-fit: contain;
  border-radius: 4px;

  border: #c4dbd5 1px solid;
  aspect-ratio: 1 / 1;
}

.product_holder:hover .image {
  opacity: 0.3;
}

.product_holder:hover .hover_text {
  opacity: 1;
}
.details {
  font-size: 14px;
  backdrop-filter: blur(3px);
  padding-top: 4px;
  display: flex;
  padding: 8px;
  border-radius: 8px;
}
button {
  background: #008060;
  border: none;
  border-radius: 4px;
  color: white;
}

button > a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

button > a > img {
  margin: 0 4px;
}

.aN {
  padding: 6px 8px;
}

.aN > p {
  font-size: 14px;
}

.btn {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.btn > button {
  width: 95%;
  padding: 12.5px 0;
}

.search > img {
  margin-left: 4px;
}

input::placeholder {
  color: #92aaa3;
}
.selected-image {
  outline: 1px solid #008060;
  border-radius: 5px;
}
</style>
