export default {
  getCartMap: (state) => state.cart_map,
  getAppNavDisplay: (state) => state.display_app_nav,
  getCheckoutCart: (state) => state.cart_checkout,
  getCheckoutCustomer: (state) => state.checkout_customer,
  getCustomers: (state) => state.customers,
  getCustomerStats: (state) => state.customer_stats,
  getCustomerToEdit: (state) => state.customer_to_edit,
  // getEmail: (state) => state.unregistered_email,
  // getEmailStatus: (state) => state.email_verified,
  // getFilterOption: (state) => state.filter_option,
  getHasCustomer: (state) => state.has_customer,
  getHasProduct: (state) => state.has_product,
  getHasSale: (state) => state.has_sale,
  getMetrics: (state) => state.metrics,
  getSalesReport: (state) => state.sales_report,
  // getComponentSettings: (state) => state.component_settings,
  getInventory: (state) => state.inventory,
  getLoggedIn: (state) => state.logged_in,
  getNavPosition: (state) => state.nav_position,
  getOrders: (state) => state.orders,
  getOrder: (state) => state.order,
  getOwners: (state) => state.owners,
  getProductToEdit: (state) => state.to_be_editted,
  // getSettingsState: (state) => state.settings,
  getSettlement: (state) => state.settlement,
  // getState: (state) => state,
  getStore: (state) => state.store,
  // getStoreSlug: (state) => state.store_slug,
  // getCampaignTemplates: (state) => state.campaign_templates,
  getUnpackedCart: (state) => state.cart_unpacked,
  // getUnsavedChange: (state) => state.unsaved_change,
}