<script>
import SearchBar from "@/components/SearchBar.vue";
import { mapGetters } from "vuex";
import * as mutationTypes from "@/store/mutationTypes";
import plusWhite from "@/assets/Images/plusWhite.svg";
import hamMenu from "@/assets/Images/hamMenu.svg";
import phoneIcon from "@/assets/Images/phoneIcon.svg";
import pen from "@/assets/Images/pen.svg";
import bin from "@/assets/Images/dustbin.svg";
import CustomHeader from "@/components/CustomHeader.vue";
export default {
  name: "SelectCustomer",
  components: {
    SearchBar,
    CustomHeader,
  },
  data() {
    return {
      customer_id: 0,
      plusWhite,
      hamMenu,
      phoneIcon,
      pen,
      bin,
      visible: false,
      input: "",
      selectedOption: "",
      activeCustomer: null,
    };
  },
  methods: {
    checkout() {
      if (this.customer_id != 0) {
        this.$store.commit(
          mutationTypes.SAVE_SELECTED_CUSTOMER,
          this.customer_id
        );
        this.$router.push({ name: "Checkout" });
      } else {
        alert("Please select a customer");
      }
    },
    selectCustomer(id, i) {
      this.toggleCustomer(id);
      this.activeCustomer = i;
    },
    toggleCustomer(id) {
      switch (this.customer_id) {
        case 0:
          this.customer_id = id;
          break;
        case id:
          this.customer_id = 0;
          break;
        default:
          this.customer_id = id;
      }
    },
  },
  computed: {
    ...mapGetters({
      customers: "getCustomers",
    }),
    filteredList() {
      return this.customers.filter(
        (customer) =>
          customer.first_name
            .toLowerCase()
            .includes(this.input.toLowerCase()) ||
          customer.last_name.toLowerCase().includes(this.input.toLowerCase()) ||
          customer.phone.toLowerCase().includes(this.input.toLowerCase())
      );
    },
    totalCustomers() {
      return this.filteredList.length;
    },
    anonymous() {
      return this.customers[this.customers.findIndex(customer => customer.first_name == "Anonymous")]
    }

  },
  mounted() {
    this.$store.commit(mutationTypes.DISPLAY_APP_NAV, false);
  },
  unmounted() {
    this.$store.commit(mutationTypes.DISPLAY_APP_NAV, true);
  },
};
</script>
<template>
  <div class="container">
    <CustomHeader text="Select customer" />
    <div style="height: 30px;">
      <!-- pushes CustomHeader down -->
    </div>
    <div class="card_container">
      <div class="main">
        <div class="semi-header">
          <SearchBar v-model="input" placeholder="Search for customers..." />
          <button class="add_cust">
            <RouterLink :to="'/customer_form/cart'">
              <img :src="plusWhite" alt="a green plus icon" /> Add Customer
            </RouterLink>
          </button>
        </div>
        <p class="cl">
          All customer{{ totalCustomers === 1 ? "" : "s" }} ({{
            totalCustomers
          }})
        </p>       
        <div
          class="customer-cards"
          :class="{ active: customer_id == anonymous.id }"
          @click="selectCustomer(anonymous.id, i)"
        >
          <img
            :src="require('@/assets/Images/card1.svg')"
            alt="customer's img or avatar"
          />
          <div class="second_section">
            <div class="first_holder">
              <h1>Unknown</h1>
              <div class="phone-number">
              </div>
            </div>
          </div>
        </div>

        <div
          v-for="(customer, i) in filteredList"
          :key="i"
          class="customer-cards"
          :class="{ active: customer_id == customer.id }"
          @click="selectCustomer(customer.id, i)"
        >
          <span v-if="customer.first_name != 'Anonymous'">
            <img
              :src="require('@/assets/Images/card1.svg')"
              alt="customer's img or avatar"
            />
          </span>
          <span v-if="customer.first_name != 'Anonymous'" class="second_section">
            <div>
              <div class="first_holder">
                <h1>{{ customer.first_name }} {{ customer.last_name }}</h1>

                <div class="phone-number">
                  <p>{{ customer.phone }}</p>
                  <img
                    v-if="customer.phone != ''"
                    :src="phoneIcon"
                    alt="phoneIcon"
                  />
                </div>
              </div>

              <div class="second_holder" style="display: none;">
                <label>
                  <input
                    type="radio"
                    :id="'customer-' + i"
                    :value="i"
                    v-model="activeCustomer"
                    class="radio-button"
                    @change="activeCustomer = i"
                  />
                </label>
              </div>
              <div v-if="visible" class="options">
                <RouterLink to="/editItem" class="truck">
                  <img :src="truckIcon" alt="truck icon" /> Edit Customer
                </RouterLink>
                <RouterLink to="/changePrice" class="cancel">
                  <img :src="cancelStroke" alt="cancelStroke" /> Delete customer
                </RouterLink>
              </div>

            </div>
          </span>
        </div>
        <div v-if="input && filteredList.length === 0" class="item error">
          <p>No results found!</p>
        </div>
      </div>

      <div style="width: 100%; position: fixed; bottom: 0">
        <div class="btn">
          <span class="dontremove"></span>
          <button @click="checkout()">Go to checkout</button>
          <span class="dontremove"></span>
        </div>
      </div>
    </div>
  </div>

  <div style="display: block; height: 35px"><!--props page up--></div>
</template>

<style scoped>
.container {
  color: #002e22;
  width: 100%;
}
.main {
  width: 100%;
}
.semi-header {
  display: flex;
  margin: 24px 0px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.card_container {
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.dontremove {
  margin: 8px;
}

button {
  color: white;
  font-size: 14px;
  background: #008060;
  border-radius: 4px;
  border: none;
}
.add_cust {
  padding: 6px 8px;
}

a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
}

button > a > img {
  margin: 0px 4px;
}

.customer-cards {
  width: 100%;
  border-radius: 8px;

  display: flex;
  padding: 14px 0px;
  justify-content: center;
  margin: 8px 0px;
  background: #f5fcf4;
  border: #c4dbd5 0.5px solid;
}
.active {
  border: 1px solid #4caf50;
}
.customer-cards > img {
  width: 52px;
  height: 52px;
  object-fit: contain;
}

.second_section {
  width: 70%;
  margin-left: 12px;
  display: flex;
  justify-content: space-between;
}

.first_holder,
.second_holder {
  display: flex;
  justify-content: center;
}

.first_holder {
  flex-direction: column;
}

.first_holder > h1 {
  font-size: 16px;
  color: #002e22;
  margin-bottom: 4px;
}
.radio-button {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  background-color: lightgray;
  transition: background-color 0.3s ease;
}

.radio-button:checked {
  background-color: #93cab9; /* Army green */
  border-color: #93cab9;
}

.radio-button:checked::after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #008060; /* Deep green */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.orders {
  background-color: #f9f3db;
  font-size: 14px;
  color: #a75a00;
  margin: 0;
  padding: 4px 8px;
  border-radius: 24px;
  border: none;
}

.phone-number {
  display: flex;
  font-size: 14px;
  color: #355f53;
}

.phone-number > p {
  margin-right: 4px;
}
.cl{
  margin-bottom:16px;
}
.btn {
  display: flex;
}
.btn > button {
  width: 100%;
  padding: 12.5px 0;
  margin: 12px 0;
}
.options {
  position: absolute;
  border-radius: 8px;
  right: 0;
  padding: 12px;
  background: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: -2px 4px 16px 0px #d1e4de80;
}
.options a {
  padding: 8px;
  text-decoration: none;
  border-bottom: #c4dbd5 0.3px solid;
  color: #355f53;
}
.options a > img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.active-card {
  border: 2px solid blue;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.btn:disabled {
  background-color: grey;
  cursor: not-allowed;
}
</style>
